import amphurs from "@/json/amphurs.json";
import provinces from "@/json/provinces.json";
import tambons from "@/json/tambons.json";
import zipcodes from "@/json/zipcodes.json";

const getProvinceName = (code) => {
  return getProvinceObject(code)?.province_name || "-";
};

const getAmphurName = (code) => {
  return getAmphurObject(code)?.amphur_name || "-";
};

const getTambonName = (code) => {
  return getTambonObject(code)?.tambon_name || "-";
};

const getProvinceObject = (code) => {
  return getListProvince(code).find((m) => m.province_id == code) || null;
};

const getAmphurObject = (code) => {
  const _tmp = [...amphurs].find((m) => m.amphur_code == code) || null;
  if (_tmp) {
    return {
      province_id: _tmp.province_code,
      amphur_id: _tmp.amphur_code,
      amphur_name: _tmp.amphur_name,
    };
  } else {
    return null;
  }
};

const getTambonObject = (code) => {
  const _tmp = [...tambons].find((m) => m.tambon_code == code) || null;
  if (_tmp) {
    return {
      province_id: _tmp.province_code,
      amphur_id: _tmp.amphur_code,
      tambon_id: _tmp.tambon_code,
      tambon_code: _tmp.tambon_code,
      tambon_name: _tmp.tambon_name,
    };
  } else {
    return null;
  }
};

const getListProvince = () => {
  return [...provinces].map((m) => {
    return {
      province_id: m.province_code,
      province_name: m.province_name,
    };
  });
};

const getListAmphor = (code = null) => {
  return [...amphurs]
    .filter((f) => {
      return f.province_code == code;
    })
    .map((m) => {
      return {
        province_id: m.province_code,
        amphur_id: m.amphur_code,
        amphur_name: m.amphur_name,
      };
    });
};

const getListTambon = (code = null) => {
  return [...tambons]
    .filter((f) => {
      return f.amphur_code == code;
    })
    .map((m) => {
      return {
        // province_id: m.province_code,
        amphur_id: m.amphur_code,
        tambon_id: m.tambon_code,
        tambon_code: m.tambon_code,
        tambon_name: m.tambon_name,
      };
    });
};

const getZipCode = (tumbonCode = null) => {
  const tmp = [...zipcodes].filter((f) => {
    return f.tambon_code == tumbonCode;
  })[0] || { tambon_code: null, zipcode: null };

  return tmp.zipcode;
};

export default {
  getProvinceName,
  getAmphurName,
  getTambonName,
  getProvinceObject,
  getAmphurObject,
  getTambonObject,
  getListProvince,
  getListAmphor,
  getListTambon,
  getZipCode,
};

export {
  getProvinceName,
  getAmphurName,
  getTambonName,
  getProvinceObject,
  getAmphurObject,
  getTambonObject,
  getListProvince,
  getListAmphor,
  getListTambon,
  getZipCode,
};
