import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
const END_POINT = "SetTrainingDate";

class SetTrainingService {
  async postSetTrainingDate(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/SetTraniningDate`,
      data: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getTrainingDate(id) {
    const params = {
      AirportId: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/GetTraniningDate`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data;
  }

  async postUpdateSetTrainingDate(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/UpdateTraniningDate`,
      data: params,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await httpClient(config);

    return response || {};
  }
}

export default new SetTrainingService();
