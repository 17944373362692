<template>
  <div id="AttachFile">
    <div class="ml-4 font-info">
      <div v-if="list.length == 0 && mode === 'view'" class="weight-600 my-5">
        ไม่มีเอกสารแนบ
      </div>

      <div v-else-if="list.length > 0">
        <div
          v-for="(doc, i) in list"
          :key="`doc-${doc.documentTypeID}-${i}`"
          class="row d-flex align-items-center my-1"
        >
          <div class="align-items-baseline col-xl-7 col-lg-7 col-12 d-flex">
            <div class="circle-list"></div>
            <div v-if="mode === 'add'">
              {{ doc.documentTypeName || doc.text }}
              <span class="mark-required" v-show="doc.isRequired"></span>
            </div>
            <div v-else-if="mode === 'edit'">
              {{ doc.documentTypeName || doc.text }}
              <span class="mark-required" v-show="doc.isRequired"></span>
            </div>
            <div v-else>
              {{ doc.documentTypeName || doc.text }}
              <span class="mark-required" v-show="doc.isRequired"></span>
            </div>
          </div>

          <input
            type="file"
            ref="items1"
            style="display: none"
            accept="application/pdf,image/*"
            @change="uploadFile($event, doc)"
          />
          <div class="col d-flex align-items-center">
            <button
              type="button"
              class="btn btn-light mr-3"
              @click.prevent="$refs.items1[i].click()"
              v-if="mode !== 'view'"
            >
              <font-awesome-icon icon="fa-solid fa-upload" class="pr-2" />
              อัพโหลดไฟล์
            </button>

            <div v-if="doc.file && doc.file.length > 0" class="">
              <button
                type="button"
                class="respon-device py-1 px-4 btn-primary mr-3"
                @click="onClick(doc)"
              >
                <font-awesome-icon
                  icon="fa-regular fa-file-lines"
                  class="pr-2"
                />เรียกดู
              </button>

              <button
                type="button"
                class="respon-device py-1 px-4 btn-delete"
                @click="onDeleteClick(doc, i)"
                v-if="mode !== 'view'"
              >
                <font-awesome-layers class="pr-2">
                  <font-awesome-icon icon="fa-regular fa-circle" class="" />
                  <font-awesome-icon
                    icon="fa-solid fa-minus"
                    class=""
                    transform="shrink-6"
                  />
                </font-awesome-layers>
                ลบ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String, // add, edit, view
      default: () => "add",
    },
    listDoc: {
      type: [Array],
      default: () => [],
    },
    consentFormTypeId: {
      type: Number,
      default: 0,
    },
    // personTypeID: {
    //   type: [Number, null],
    //   default: null,
    // },
    consentFormID: {
      type: [String, Number, null],
      default: null,
    },
    submit: {
      type: Boolean,
      default: () => false,
    },
    invalid: {
      type: Boolean,
      default: () => false,
    },
    resultFiles: {
      type: [Array, null],
      default: [],
    },
    sumFileSize: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_BASE_API_DOWNLOAD,
      listDocLocal: [],
      list: [],
      attachList: [],
      sumSize: 0,
    };
  },
  created() {
    window._attachfile = this;
    if (
      this.listDoc !== null &&
      this.listDoc?.length > 0 &&
      this.mode === "add"
    ) {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [] };
      });

      this.listDocLocal = [..._result];

      this.list = [...this.listDocLocal];
    } else if (this.mode == "view") {
      this.list = [...this.resultFiles];
    } else {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [], isUpdate: false };
      });

      this.listDocLocal = [..._result];

      if (this.resultFiles.length > 0) {
        this.list = [...this.resultFiles].map((m) => {
          return {
            ...m,
            isUpdate: false,
          };
        });
      } else {
        this.list = [...this.listDocLocal];
      }
    }
  },
  mounted() {
    if (
      this.listDoc !== null &&
      this.listDoc?.length > 0 &&
      this.mode === "add"
    ) {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [] };
      });

      this.listDocLocal = [..._result];

      this.list = [...this.listDocLocal];
    } else if (this.mode == "view") {
      this.list = [...this.resultFiles];
    } else {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [], isUpdate: false };
      });

      this.listDocLocal = [..._result];

      if (this.resultFiles.length > 0) {
        this.list = [...this.resultFiles].map((m) => {
          return {
            ...m,
            isUpdate: false,
          };
        });
      } else {
        this.list = [...this.listDocLocal];
      }
    }
  },
  //   updated() {
  //     this.$emit("update:resultFiles", this.list);
  //   },
  methods: {
    uploadFile(e, item) {
      if (item.file.length > 0) {
        this.alertConfirm(
          "คุณต้องการแทนที่ไฟล์เดิมใช่หรือไม่ ?",
          "",
          "แทนที่",
          "ย้อนกลับ"
        ).then((res) => {
          if (res) {
            item.file = Array.from(e.target.files);

            this.onEmit();
          }
        });
      } else {
        item.file = Array.from(e.target.files);

        this.onEmit();
      }
    },
    onClick(item) {
      if (this.mode === "add") {
        const fileURL = URL.createObjectURL(item.file[0]);
        window.open().location.href = fileURL;
      } else {
        if (item.file[0].file !== null) {
          const fileURL = `${this.apiURL}${item.file[0].file}`;
          try {
            window.open(fileURL, "_blank");
          } catch (e) {}
        } else {
          this.alertFail("ไม่พบไฟล์");
        }
      }
    },
    onDeleteClick(item, i) {
      this.alertConfirm("ยืนยันการลบเอกสาร").then((res) => {
        if (res) {
          item.file = [];

          this.$refs.items1[i].value = "";

          this.onEmit();
        }
      });
    },
    onEmit() {
      if (this.mode === "add") {
        this.sumSize = this.$utils.convert.sumFileSize([...this.list]);
        this.$emit("update:sumFileSize", this.sumSize);
        this.$emit("update:resultFiles", [...this.list]);
      } else if (this.mode === "edit") {
        const _result = this.list.map((m) => {
          return {
            ...m,
            file: m.file.length === 0 ? [] : m.file,
            isUpdate: m.file[0] instanceof File || m.file.length === 0,
          };
        });
        this.sumSize = this.$utils.convert.sumFileSize(_result);
        this.$emit("update:sumFileSize", this.sumSize);
        this.$emit("update:resultFiles", _result);
      }
    },
  },
  watch: {
    // list: {
    //   deep: true,
    //   handler: function (newval, oldVal) {
    //     if (this.mode === "add") {
    //       this.$emit("update:resultFiles", newval);
    //     } else if (this.mode === "edit") {
    //       const _result = newval.map((m) => {
    //         return {
    //           ...m,
    //           file: m.file.length === 0 ? [] : m.file,
    //           isUpdate: m.file[0] instanceof File || m.file.length === 0,
    //         };
    //       });
    //       this.$emit("update:resultFiles", _result);
    //     }
    //   },
    // },
    // personTypeID: {
    //   deep: true,
    //   handler: function (val) {
    //     if (this.list.length > 0 && val) {
    //       this.list = this.list.map((m) => {
    //         return {
    //           ...m,
    //           personTypeID: val,
    //         };
    //       });
    //     }
    //   },
    // },
    mode: {
      deep: true,
      handler: function (val) {
        if (this.mode === "add") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];

          this.list = [...this.listDocLocal];
        }
      },
    },
    listDoc: {
      deep: true,
      handler: function (val) {
        if (val !== null && val?.length > 0 && this.mode === "add") {
          const _result = val.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];

          this.list = [...this.listDocLocal];
        }
      },
    },
    resultFiles: {
      handler: function (val) {
        if (this.mode === "view") {
          if (val.length > 0) {
            this.list = [...val];
          }
        } else if (this.mode === "edit") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [], isUpdate: false };
          });

          this.listDocLocal = [..._result];

          if (val.length > 0) {
            this.list = [...val].map((m) => {
              return {
                ...m,
                isUpdate: false,
              };
            });
          } else {
            this.list = [...this.listDocLocal];
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
