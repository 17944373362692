<template>
  <div id="OfficerHeader" class="app-header">
    <div class="d-flex pr-3 up-header"></div>
    <div class="d-flex align-items-center pr-3 pl-3 main-header">
      <div class="header-project px-3 d-flex flex-fill align-items-center">
        <div>
          <div class="d-flex align-items-center">
            <div>
              <img class="mr-5" src="@/assets/logo.png" style="height: 60px" />
            </div>
            <div>
              <div>
                <span class="fontsize-h2"
                  >ระบบงานบริการ
                  <span style="color: #ffd37e">e-Service</span></span
                >
              </div>
              <div class="fontsize-h6">กรมท่าอากาศยาน</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "officer-header",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style></style>
