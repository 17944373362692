import httpClient from "../httpClient";
import {
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
} from "@/utils/convert-utils";

const END_POINT = "ConsentExclusiveLounge";
const END_POINT_OFFICER = "ConsentExclusiveLoungeOfficer";

class ConsentExclusiveLoungeService {
  async AddConsentExclusiveLounge(param) {
    let formData = new FormData();

    const {
      saveConsentExclusiveLounge,
      consentFileUploads,
      participatorModels,
    } = param;

    objToFormData(
      saveConsentExclusiveLounge,
      "saveConsentExclusiveLounge",
      formData
    );

    console.log("consent file model = ", consentFileUploads);
    consentFileUploads.forEach((c, i) => {
      const { file, ...rest } = { ...c };

      // console.log(file);
      // console.log(rest);
      objToFormDataFile(formData, rest, file || [], `consentFileUploads[${i}]`);
    });

    // console.log("parti model = ", participatorModels);
    // participatorModels.forEach((c, i) => {
    //   const { file, ...rest } = { ...c };

    //   console.log("file parti =", file);
    //   console.log("rest parti =", rest);
    //   objToFormDataFile(formData, rest, file, `participatorModels[${i}]`);
    // });

    objToFormDataParticipator(
      formData,
      participatorModels,
      "participatorModels"
    );

    const config = {
      method: "post",
      url: `${END_POINT}/AddConsentExclusiveLounge`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      data: formData,
    };

    logFormData(formData);

    // console.log("formdata : ", formData);
    const response = await httpClient(config);

    console.log("AddConsentExclusiveLounge", response);

    return response;
  }

  async updateConsentExclusiveLounge(param) {
    let formData = new FormData();

    const {
      saveConsentExclusiveLounge,
      consentFileUploads,
      participatorModels,
    } = param;

    objToFormData(
      saveConsentExclusiveLounge,
      "saveConsentExclusiveLounge",
      formData
    );

    console.log("consent file model = ", consentFileUploads);
    consentFileUploads.forEach((c, i) => {
      const { file, ...rest } = { ...c };

      // console.log(file);
      // console.log(rest);
      objToFormDataFile(formData, rest, file || [], `consentFileUploads[${i}]`);
    });

    // console.log("parti model = ", participatorModels);
    // participatorModels.forEach((c, i) => {
    //   const { file, ...rest } = { ...c };

    //   console.log("file parti =", file);
    //   console.log("rest parti =", rest);
    //   objToFormDataFile(formData, rest, file, `participatorModels[${i}]`);
    // });

    objToFormDataParticipator(
      formData,
      participatorModels,
      "participatorModels"
    );

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateConsentExclusiveLounge`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      data: formData,
    };

    logFormData(formData);

    // console.log("formdata : ", formData);
    const response = await httpClient(config);

    console.log("AddConsentExclusiveLounge", response);

    return response;
  }

  async getDetailConsentExclusiveLounge(id) {
    const param = {
      ConsetFormID: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/GetDetailConsentExclusiveLounge`,
      params: param,
    };

    const response = await httpClient(config);

    if (response.status) {
      return response.data || {};
    }
    return {};
  }

  async AddConsentExclusiveLoungeOfficer(param) {
    let formData = new FormData();

    const {
      saveConsentExclusiveLounge,
      consentFileUploads,
      participatorModels,
    } = param;

    objToFormData(
      saveConsentExclusiveLounge,
      "saveConsentExclusiveLounge",
      formData
    );

    console.log("consent file model = ", consentFileUploads);
    consentFileUploads.forEach((c, i) => {
      const { file, ...rest } = { ...c };

      // console.log(file);
      // console.log(rest);
      objToFormDataFile(formData, rest, file || [], `consentFileUploads[${i}]`);
    });

    console.log("parti model = ", participatorModels);
    participatorModels.forEach((c, i) => {
      const { file, ...rest } = { ...c };

      console.log("file parti =", file);
      console.log("rest parti =", rest);
      objToFormDataFile(formData, rest, file, `participatorModels[${i}]`);
    });

    const config = {
      method: "post",
      url: `${END_POINT_OFFICER}/AddConsentExclusiveLounge`,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
      data: formData,
    };

    logFormData(formData);

    // console.log("formdata : ", formData);
    const response = await httpClient(config);

    console.log("AddConsentExclusiveLounge", response);

    return response;
  }
}

export default new ConsentExclusiveLoungeService();
