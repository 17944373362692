import httpClient from "../httpClient";

const END_POINT = "GroupUser";

class GroupUserService {
  async getSearchGroupUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchGroupUser`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getGroupUser(id) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetGroupUser`,
      params: {
        GroupId: id,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAddGroupUser(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/AddGroupUser`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }

  async postUpdateGroupUser(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/UpdateGroupUser`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }

  async postDeleteGroupUser(groupId) {
    const config = {
      method: "post",
      url: `${END_POINT}/DeleteGroupUser`,
      data: {
        groupId: groupId,
      },
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }

  async getUserInGroup(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetUsersInGroup`,
      params: params,
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return response || {};
    }
  }
}

export default new GroupUserService();
