var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isManage)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},_vm._l((_vm.titleManage),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"text-center"},[(item.id > _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-green fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):_c('font-awesome-layers',{staticClass:"fa-2x"},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(_vm.currentStep < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1),(i < _vm.titleManage.length - 1)?_c('div',{class:_vm.currentStep - 2 < i ? 'font-gray' : 'font-green',staticStyle:{"border-bottom":"4px solid","width":"200px"}}):_vm._e()])}),0):_c('div',{staticClass:"d-flex justify-content-center align-items-center"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
          (item.id !== 6 && item.id !== 8) ||
          (item.id === 6 && _vm.isTraining) ||
          (item.id === 8 && _vm.isPayment)
        )?_c('div',{staticClass:"text-center"},[(item.id > _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-green fa-2x cursor-pointer",attrs:{"icon":"fa-regular fa-circle"},on:{"click":function($event){return _vm.onClick(item)}}}):_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClick(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(item.isUsed || _vm.currentSection == item.id)?_c('div',{staticClass:"font-green weight-600 underline red"},[_vm._v(" "+_vm._s(item.text)+" ")]):(_vm.currentStep < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
          i < _vm.title.length - 1 &&
          ((item.id !== 6 && item.id !== 8) ||
            (item.id === 6 && _vm.isTraining) ||
            (item.id === 8 && _vm.isPayment))
        )?_c('div',{class:item.id >= _vm.currentStep ? 'font-gray' : 'font-green',staticStyle:{"border-bottom":"4px solid","width":"200px"}}):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }