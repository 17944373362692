import httpClient from "../httpClient";

const END_POINT = "Dashboard";

class DashboardService {
  async getDashboardConsentFormYear(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/DashboardConsentFormYear`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data || [];
  }

  async getDashboardConsentFormDetail(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/DashboardConsentFormDetail`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async getDashboardConsentFormDetail(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/DashboardConsentFormDetail`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async getDashboardConsentFormApprove(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/DashboardConsentFormApprove`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async getDashboardConsentFormPaymentType(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/DashboardConsentFormPaymentType`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data || {};
  }
}

export default new DashboardService();
