import httpClientPublic from "../httpClientPublic";
import httpClient from "../httpClient";

import axios from "axios";

const ConsumerSecret = process.env.VUE_APP_ConsumerSecret;
const ConsumerKey = process.env.VUE_APP_ConsumerKey;
const AgentID = process.env.VUE_APP_AgentID;
const END_POINT = "DGA";
class DGAService {
  async getConnectPortal(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ConnectPortal`,
      params: params,
    };

    const response = await httpClientPublic(config);

    return response || {};
  }

  async getUserDetail(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetUserDetail`,
      params: params,
    };

    const response = await httpClientPublic(config);
    return response || {};
  }

  async getCheckAddressRequired() {
    const config = {
      method: "get",
      url: `${END_POINT}/CheckAddressRequired`,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postLoginWithUpdateEmail(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/LoginWithUpdateEmail`,
      params: params,
    };
    const response = await httpClient(config);
    return response || {};
  }

  async getToken() {
    const _url = "https://api.egov.go.th/ws/auth/validate";

    const params = {
      ConsumerSecret: ConsumerSecret,
      AgentID: AgentID,
    };

    axios
      .get(_url, {
        params: {
          ...params,
        },
        headers: {
          "Consumer-Key": ConsumerKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // handle success
        return response?.data?.Result || null;
      })
      .catch((error) => {
        // handle error
        return null;
      });
  }

  async postDeproc(params, accessToken) {
    const _url = process.env.VUE_APP_DGA_deproc;

    axios
      .post(_url, params, {
        headers: {
          "Consumer-Key": ConsumerKey,
          "Content-Type": "application/json",
          Token: accessToken,
        },
      })
      .then((response) => {
        // handle success
        return response?.data?.result || null;
      })
      .catch((error) => {
        // handle error
        return null;
      });
  }

  async postMock(params, accessToken) {
    // const params = {
    //     AppId: this.appId, //"bfcfe3f4-6663-4159-a21a-045ea90b98ee",
    //     mToken: this.mToken,
    //   };

    const mockUrl =
      "https://api.egov.go.th/ws/dga/czp/uat/v1/core/shield/data/mock";

    axios
      .post(mockUrl, params, {
        headers: {
          "Consumer-Key": ConsumerKey,
          "Content-Type": "application/json",
          Token: accessToken,
        },
      })
      .then((response) => {
        // handle success
        return response?.data?.result || {};
      })
      .catch((error) => {
        // handle error
        return null;
      });
  }
}
export default new DGAService();
