<template>
  <div id="ConsentNavi" class="my-4 consent-navi">
    <font-awesome-icon icon="fa-solid fa-desktop" class="pr-3" />
    <span>หน้าหลัก</span>
    <span>{{ consentHeaderTitle }}</span>
    <span v-if="consentFormTitle">{{ consentFormTitle }}</span>
  </div>
</template>

<script>
export default {
  name: "consent-navi",
  props: {
    mode: {
      type: String,
      default: "add", // add, edit, view
    },
    consentFormTitle: {
      type: [String, null],
      default: "",
    },
  },
  computed: {
    consentHeaderTitle() {
      return this.mode === "edit"
        ? "แก้ไขใบคำขอใช้บริการ"
        : this.mode === "add"
        ? "สร้างใบคำขอใช้บริการ"
        : "รายละเอียดคำขอใช้บริการ";
    },
  },
};
</script>

<style lang="scss">
.consent-navi {
  span:not(:last-child) {
    &::after {
      content: ">>";
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
</style>
