import AccountService from "@/services/api/account.service";
import { DGAService } from "@/services/main.service";
import { TokenService } from "@/services/storage.service";

const user = {
  accessToken: null,
  refreshToken: null,
};

const detail = {
  info: {},
  currentAddress: {},
  billingAddress: {},
  corporationAddress: {},
};

const initialState = {
  userInfo: { ...detail },
  userId: null,
  user: { ...user },
};

const actions = {
  login({ commit }, data) {
    return AccountService.login(data).then(
      (res) => {
        if (res) {
          const { accessToken, refreshToken } = { ...res };
          const user = {
            accessToken: accessToken,
            refreshToken: refreshToken,
          };

          commit("loginSuccess", user);
          return Promise.resolve(res.userId);
        } else {
          commit("loginFailure");
          return Promise.resolve(undefined);
        }
      },
      (error) => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  userInfo({ commit }) {
    return AccountService.getUserInfo().then(
      async (res) => {
        const result = {
          ...res,
        };

        await DGAService.getCheckAddressRequired().then((r) => {
          result["haveAddress"] = !r.data.isEmpty;
        });

        commit("setUserInfo", result);
        return Promise.resolve(result);
      },
      (error) => {
        commit("setUserInfo", {});
        return Promise.reject(error);
      }
    );
  },
  logout({ commit }) {
    return AccountService.logout().then(
      (res) => {
        commit("logoutSuccess");
        return Promise.resolve(res);
      },
      (error) => {
        commit("logoutFailure");
        return Promise.reject(error);
      }
    );
  },
  loginPortal({ commit }, data) {
    const { accessToken, refreshToken } = { ...data };
    const user = {
      accessToken: accessToken,
      refreshToken: refreshToken,
    };

    if (accessToken && refreshToken) {
      TokenService.saveToken(accessToken);
      TokenService.saveRefreshToken(refreshToken);
    }

    commit("loginSuccess", user);
    return Promise.resolve(data.userId);
  },
  logoutPortal({ commit }, data) {
    return AccountService.logout().then(
      (res) => {
        commit("logoutSuccess");
        return Promise.resolve(res);
      },
      (error) => {
        commit("logoutFailure");
        return Promise.reject(error);
      }
    );
  },
};

const mutations = {
  loginSuccess(state, user) {
    state.user = { ...user };
  },
  loginFailure(state) {
    state.userInfo = {};
    state.userId = null;
    state.user = {};
  },
  logoutSuccess(state) {
    state.userInfo = {};
    state.userId = null;
    state.user = {};
  },
  logoutFailure(state) {
    state.userInfo = {};
    state.userId = null;
    state.user = {};
  },
  setUserInfo(state, detail = {}) {
    state.userInfo = { ...detail };
    state.userId = detail?.info?.userId || null;
  },
};

export const account = {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
