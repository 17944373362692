import httpClient from "../httpClient";
const END_POINT = "ConsentCarTemporary";
const END_POINT_OFFICER = "ConsentCarPermanentOfficer";

import {
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
} from "@/utils/convert-utils";

class ConsentCarTemporary {
  async addConsentCarTemporary(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/AddConsentCarTemporary`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    logFormData(formData);

    const response = await httpClient(config);
    return response;
  }

  async updateConsentCarTemporary(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateConsentCarTemporary`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    logFormData(formData);

    const response = await httpClient(config);
    console.log("response update = ", response);
    return response;
  }

  async addConsentCarTemporaryOfficer(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT_OFFICER}/AddConsentCarTemporary`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    logFormData(formData);

    const response = await httpClient(config);
    return response;
  }

  async getDetailConsentCarTemporary(id) {
    const param = {
      ConsetFormID: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/GetConsentCarTemporary`,
      params: param,
    };

    const response = await httpClient(config);

    if (response.status) {
      return response.data || {};
    }
    return {};
  }
}

export default new ConsentCarTemporary();
