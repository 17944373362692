<template>
  <div
    id="AdminLayout"
    :class="[isMenuCollapse ? 'menu-collapse' : 'menu-expand']"
  >
    <admin-header></admin-header>

    <admin-sidebar @toggle-menu-func="isMenuCollapse = $event"></admin-sidebar>

    <main class="content-wrapper-admin">
      <router-view></router-view>
    </main>

    <div class="sidemenu-backdrop"></div>

    <app-footer customClass="admin-footer"></app-footer>
  </div>
</template>

<script>
import AdminHeader from "./Header.vue";
import AdminSidebar from "./Sidebar.vue";
import AppFooter from "@/components/layouts/Footer.vue";

export default {
  name: "admin-layout",
  components: { AdminHeader, AdminSidebar, AppFooter },
  data() {
    return {
      isMenuCollapse: false,
    };
  },
  created() {
    window.addEventListener("click", this.clickSideMenuBackdrop);
  },
  destroyed() {
    window.removeEventListener("click", this.clickSideMenuBackdrop);
  },
  methods: {
    clickSideMenuBackdrop(e) {
      const _target = e.target.classList || [];
      if (_target[0] === "sidemenu-backdrop") {
        this.isMenuCollapse = true;
      }
    },
  },
};
</script>

<style></style>
