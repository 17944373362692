<template>
  <div class="digital-signature">
    <b-modal
      id="my-modal1"
      v-model="showModal"
      modal-class="custom-modal-defualt"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
      :hide-header="true"
      centered
      @shown="show"
      @change="cancel"
      @cancel="cancel"
    >
      <template v-slot:default="">
        <div class="body-modal">
          <div v-show="!isLoading">
            <div class="d-flex justify-content-between">
              <div></div>
              <div class="text-right">
                <button @click="cancel()">X</button>
              </div>
            </div>
            <div class="text-center">
              <span>กรอกข้อมูลของผู้มีสิทธิ์อนุมัติคำขอ</span>
              <br />
              <span
                >เพื่อยืนยันตัวตน
                และลงลายมือชื่ออิเล็กทรอนิกส์ในเอกสารคำขอ</span
              >
            </div>
            <div>
              <input
                id="swal-input1"
                class="form-control mt-3"
                type="text"
                placeholder="กรอก Username ของผู้มีสิทธิ์ลงลายมือชื่อ"
                v-model="username"
                :class="{
                  validate: submit && !$v.username.required,
                }"
              />
              <input
                id="swal-input2"
                class="form-control mt-3"
                type="password"
                placeholder="กรอก 'รหัสผ่านสำหรับลงนามอิเล็กทรอนิกส์'"
                v-model="password"
                :class="{
                  validate: submit && !$v.password.required,
                }"
              />
            </div>
            <div class="text-center my-3">
              <button
                type="button"
                @click="callService()"
                class="btn btn-warning"
              >
                ตกลง
              </button>
            </div>
          </div>
          <div v-show="isLoading">
            <div class="d-flex justify-content-center mb-3 mt-3">
              <div class="loader"></div>
            </div>
            <div class="d-col text-center">อยู่ระหว่างดำเนินการ</div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ConsentService } from "@/services/main.service";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    ConsentFormID: {
      type: String,
      default: null,
    },
    ConsentFormTypeID: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showModal: false,
      username: null,
      password: null,
      isLoading: false,
      submit: false,
    };
  },
  validations: {
    username: { required },
    password: { required },
  },
  methods: {
    show() {
      const el = document.querySelector("#my-modal1");
      const test = el.querySelectorAll(".modal-content");
      test[0].removeAttribute("tabindex");
    },
    cancel() {
      this.$emit("change", false);
      this.showModal = false;
    },
    async callService() {
      this.submit = true;

      if (this.$v.$invalid) {
        return;
      }
      // call digital signature
      this.isLoading = true;

      const param = {
        consentFormID: this.ConsentFormID,
        userAd: this.username,
        password: this.password,
      };
      ConsentService.getConsentLicense(param)
        .then(
          (res) => {
            return true;
          },
          (err) => {
            this.isLoading = false;
            this.alertFail(err?.data?.myMessage);
            return false;
          }
        )
        .then((res) => {
          this.showModal = false;
          this.$emit("change", false);
          if (res) {
            // update status
            const statusParam = {
              consentFormID: this.ConsentFormID,
              consentStatusID: 9,
              remark: null,
            };

            ConsentService.postSetStatusConsent(statusParam)
              .then((res1) => {
                if (res1.status) {
                  this.alertSuccess("ทำรายการสำเร็จ").then((result) => {
                    if (result) {
                      this.isLoading = false;
                      this.$router.push({
                        name: "ServiceRequestDetail",
                        params: {
                          consentFormId: this.ConsentFormID,
                          consentFormTypeId: this.ConsentFormTypeID,
                          status: 9,
                        },
                      });
                    }
                  });
                }
              })
              .catch((err) => {
                this.isLoading = false;
                this.alertFail(err?.data?.myMessage);
              });
          }
        })
        .catch((err) => {
          this.isLoading = false;
          this.alertFail(err?.data?.myMessage);
          return false;
        });
    },
  },
  watch: {
    isShowModal: {
      handler: function (val, oldVal) {
        if (val) {
          this.showModal = val;
        }
      },
      deep: true,
    },
  },
};
</script>

<style></style>
