import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
const END_POINT = "Payment";

class PaymentService {
  async getConsentFormPrice(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ConsentFormPrice`,
      params: params,
    };
    const response = await httpClient(config);
    return response?.data || [];
  }
}

export default new PaymentService();
