<template>
  <div id="OfficerLayout">
    <officer-header></officer-header>

    <main class="content-wrapper">
      <router-view></router-view>
    </main>

    <app-footer customClass="officer-footer"></app-footer>
  </div>
</template>

<script>
import OfficerHeader from "./Header.vue";
import AppFooter from "@/components/layouts/Footer.vue";
export default {
  name: "officer-layout",
  components: {
    OfficerHeader,
    AppFooter,
  },
};
</script>

<style></style>
