import httpClient from "../httpClient";

const END_POINT = "Report";
class Report {
  async getApproveReport(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ApproveReport`,
      params: {
        ...params,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getNotApproveReport(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/NotApproveReport`,
      params: {
        ...params,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async getLateReport(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/LateReport`,
      params: {
        ...params,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getConsentAllReport(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/ConsentAllReport`,
      params: {
        ...params,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
}
export default new Report();
