<template>
  <div id="AppFooter" class="text-center app-footer" :class="customClass">
    Copyright By Piesoft Co.,Ltd. | Version {{ appVersion }}
  </div>
</template>

<script>
export default {
  name: "app-footer",
  props: {
    customClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      appVersion: null,
    };
  },
  created() {
    this.appVersion = process.env.VUE_APP_BASE_WEB_VERSION_SHOW;
  },
};
</script>

<style></style>
