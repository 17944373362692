const menuList = [
  {
    menuId: 1,
    parentId: 0,
    title: "ข้อมูลคำขอใช้บริการ",
    titleCollapse: "ข้อมูลคำขอ",
    iconClass: ["fas", "home"],
    auth: ["auth_request"],
    hasSub: true,
  },
  {
    menuId: 2,
    parentId: 1,
    title: "ประวัติคำขอใช้บริการ",
    titleCollapse: "ประวัติคำขอ",
    to: "ServiceRequestOfficer",
    auth: ["auth_requesthis"],
  },
  {
    menuId: 3,
    parentId: 1,
    title: "กำหนดวันที่อบรม/ทดสอบ",
    titleCollapse: "วันที่อบรม/ทดสอบ",
    to: "TrainingSchedule",
    auth: ["auth_training"],
  },
  {
    menuId: 4,
    parentId: 0,
    title: "รายงาน",
    titleCollapse: "รายงาน",
    iconClass: ["far", "file-lines"],
    auth: ["auth_report"],
    hasSub: true,
  },
  {
    menuId: 5,
    parentId: 4,
    title: "รายงาน Operation",
    titleCollapse: "Operation",
    to: "OperationReport",
    auth: ["auth_operation"],
  },
  {
    menuId: 6,
    parentId: 4,
    title: "รายงาน Dashboard",
    titleCollapse: "Dashboard",
    to: "DashboardReport",
    auth: ["auth_dashboard"],
  },
  {
    menuId: 7,
    parentId: 0,
    title: "ข้อมูลสมาชิก",
    titleCollapse: "ข้อมูลสมาชิก",
    iconClass: ["fas", "gear"],
    // iconClass: ["fas", "cog"],
    to: "MemberList",
    auth: ["auth_member"],
  },
  {
    menuId: 8,
    parentId: 0,
    title: "ข้อมูลผู้ใช้งาน",
    titleCollapse: "ข้อมูลผู้ใช้งาน",
    iconClass: ["fas", "user-gear"],
    auth: ["auth_management"],
    // iconClass: ["far", "user-gear"],
    hasSub: true,
  },
  {
    menuId: 9,
    parentId: 8,
    title: "กลุ่มสิทธิผู้ใช้งาน",
    titleCollapse: "กลุ่มสิทธิ",
    to: "GroupPermission",
    auth: ["auth_grouppermission"],
  },
  {
    menuId: 10,
    parentId: 8,
    title: "ผู้ใช้งาน",
    titleCollapse: "ผู้ใช้งาน",
    to: "UserManagement",
    auth: ["auth_user"],
  },
  {
    menuId: 11,
    parentId: 8,
    title: "ประวัติการใช้งานระบบ",
    titleCollapse: "ประวัติ",
    to: "HistoryUse",
    auth: ["auth_usinghistory"],
  },
  {
    menuId: 12,
    parentId: 0,
    title: "ตรวจสอบข้อมูลประชาชน (ระบบ Linkage Center)",
    titleCollapse: "Linkage Center",
    iconClass: ["fas", "link"],
    to: "LinkageCenter",
    auth: ["auth_linkage"],
  },
];

// const breadcrumbs = [
//   {
//     title: "หน้าแรก",
//     to: "FirstPage",
//     iconClass: "home",
//     auth: [""],
//     children: [
//       {
//         title: "แก้ไขข้อมูลส่วนตัว",
//         to: "EditProfile",
//         auth: [""],
//       },
//     ],
//   },
//   {
//     title: "ระบบลงเวลาปฏิบัติราชการ",
//     to: "TimeInOut",
//     iconClass: "timeinout",
//     auth: [""],
//     children: [
//       {
//         title: "กรอกใบลืมสแกน",
//         to: "TimeInOutForm",
//         auth: [""],
//       },
//       {
//         title: "ลงเวลาปฏิบัติราชการย้อนหลัง",
//         to: "TimeInOutLeaveform",
//         auth: [""],
//       },
//       {
//         title: "รายการลงเวลาย้อนหลัง",
//         to: "TimeStamp",
//         auth: [""],
//       },
//     ],
//   },
//   {
//     title: "ระบบวันลา",
//     iconClass: "leave",
//     disabled: true,
//     children: [
//       {
//         title: "รายการใบลา (ลาป่วย, ลากิจ, ลาพักผ่อน)",
//         to: "LeaveTime",
//         auth: ["leave"],
//       },
//       {
//         title: "รายการใบลา (ลาประเภทอื่นๆ)",
//         to: "LeaveOther",
//         auth: ["leave"],
//       },
//     ],
//   },
//   {
//     title: "ระบบอนุมัติ",
//     iconClass: "approve",
//     disabled: true,
//     children: [
//       {
//         title: "อนุมัติใบลา",
//         to: "ApproveLeave",
//         auth: ["approve_leave"],
//       },
//       {
//         title: "อนุมัติใบลืมสแกน",
//         to: "ApproveTime",
//         auth: ["approve_time"],
//       },
//       {
//         title: "อนุมัติการทำงานล่วงเวลา",
//         to: "ApproveOverTime",
//         auth: ["approve_overtime"],
//         children: [
//           {
//             title: "อนุมัติการขอเบิกการทำงานล่วงเวลา",
//             to: "ApproveOverTimeDetail",
//             auth: ["approve_overtime"],
//           },
//         ],
//       },
//       {
//         title: "มอบหมายผู้อนุมัติแทน",
//         to: "ApproveAssign",
//         auth: ["approve_assign"],
//       },
//     ],
//   },
//   {
//     title: "ระบบการทำงานล่วงเวลา",
//     to: "OverTime",
//     iconClass: "overtime",
//     auth: [""],
//     children: [
//       {
//         title: "รายการเบิกการทำงานล่วงเวลา",
//         to: "AddWithDrawal",
//         auth: [""],
//       },
//     ],
//   },
//   {
//     title: "ระบบรายงาน",
//     to: "ReportIndex",
//     iconClass: "report",
//     auth: ["auth_report"],
//     children: [
//       {
//         title: "บัญชีลงเวลาปฏิบัติงานของข้าราชการ พนักงานราชการ ลูกจ้างประจำ",
//         to: "Report1",
//         auth: ["auth_report"],
//       },
//       {
//         title: "รายงานบัญชีการลาและมาสาย",
//         to: "Report2",
//         auth: ["auth_report"],
//       },
//       {
//         title: "รายงานสถิติการลาและมาสาย",
//         to: "Report3",
//         auth: ["auth_report"],
//       },
//       {
//         title:
//           "รายงานบัญชีการมาปฏิบัติราชการของข้าราชการ พนักงาน และลูกจ้างประจำสำนักเลขาธิการคณะรัฐมนตรี",
//         to: "Report4",
//         auth: ["auth_report"],
//       },
//       {
//         title:
//           "รายงานการจ่ายเงินค่าตอบแทนการปฏิบัติงานนอกเวลาราชการของสำนัก / กอง / กลุ่ม แบบรายเดือน (กง.3001)",
//         to: "Report5",
//         auth: ["auth_report"],
//       },
//       {
//         title:
//           "รายงานแสดงรายชื่อผู้อยู่ปฏิบัติงานนอกเวลาราชการของสำนัก / กอง / กลุ่ม แบบรายวัน (กง.3002)",
//         to: "Report6",
//         auth: ["auth_report"],
//       },
//       {
//         title: "รายงานผลการปฏิบัติงานนอกเวลาราชการ แบบรายบุคคล (กง.3003)",
//         to: "Report7",
//         auth: ["auth_report"],
//       },
//       {
//         title: "Report8",
//         to: "Report8",
//         auth: ["auth_report"],
//       },
//       {
//         title: "รายงานสรุปยอดเงินค่าตอบแทนการปฏิบัติงานล่วงเวลา",
//         to: "Report9",
//         auth: ["auth_report"],
//       },
//       {
//         title: "บัญชีลงเวลาเข้า-ออกงาน (ข้อมูลย้อนหลังถึงเดือนกันยายน 2564)",
//         to: "Report10",
//         auth: ["auth_report"],
//       },
//       {
//         title:
//           "รายงานบัญชีการลาและมาสาย ประจำปีงบประมาณ (ข้อมูลย้อนหลังถึงเดือนกันยายน 2564)",
//         to: "Report11",
//         auth: ["auth_report"],
//       },
//       {
//         title: "รายงานสถิติการลาและมาสาย (ข้อมูลย้อนหลังถึงเดือนกันยายน 2564)",
//         to: "Report12",
//         auth: ["auth_report"],
//       },
//       {
//         title:
//           "รายงานบัญชีการมาปฏิบัติราชการรายเดือน (ข้อมูลย้อนหลังถึงเดือนกันยายน 2564)",

//         to: "Report13",
//         auth: ["auth_report"],
//       },
//     ],
//   },
//   {
//     title: "ข่าวสารองค์กร",
//     iconClass: "news",
//     to: "News",
//     auth: ["news"],
//     children: [
//       { title: "สร้างข่าวประชาสัมพันธ์", to: "CreateNews", auth: [""] },
//       { title: "แก้ไขข่าวประชาสัมพันธ์", to: "EditNews", auth: [""] },
//       { title: "รายละเอียดข่าว", to: "NewsDetail", auth: [""] },
//     ],
//   },
//   {
//     title: "ระบบบริหารจัดการข้อมูล",
//     iconClass: "management",
//     disabled: true,
//     children: [
//       {
//         title: "ข้อมูลผู้ใช้งาน",
//         to: "Employee",
//         auth: [""],
//         children: [
//           {
//             title: "ลงทะเบียนผู้ใช้งาน",
//             to: "RegisterType",
//             auth: [""],
//           },
//           {
//             title: "ลงทะเบียนผู้ใช้งาน",
//             to: "Register",
//             auth: [""],
//           },
//           {
//             title: "แก้ไขข้อมูลผู้ใช้งาน",
//             to: "EmployeeDetail",
//             auth: [""],
//           },
//           {
//             title: "แก้ไขข้อมูลผู้ใช้งาน",
//             to: "EmployeeDetailLogin",
//             auth: [""],
//           },
//         ],
//       },
//       {
//         title: "กลุ่มผู้ใช้งานและการกำหนดสิทธิ์",
//         to: "UserTypePermission",
//         auth: [""],
//         children: [
//           {
//             title: "เพิ่มกลุ่มผู้ใช้งาน",
//             to: "AddUserPermission",
//             auth: [""],
//           },
//           {
//             title: "แก้ไขกลุ่มผู้ใช้งาน",
//             to: "EditUserPermission",
//             auth: [""],
//           },
//         ],
//       },
//       {
//         title: "สังกัด (สำนัก/กอง/กลุ่ม)",
//         to: "Department",
//         auth: [""],
//       },
//       {
//         title: "จัดการกลุ่มตำแหน่ง",
//         to: "Position",
//         auth: [""],
//       },
//       // {
//       //   title: 'ตำแหน่ง',
//       //   to: 'ManagePosition',
//       //   auth: ['']
//       // },
//       {
//         title: "ประเภทการลา",
//         to: "LeaveType",
//         auth: [""],
//       },
//       {
//         title: "พื้นที่ลงเวลาปฏิบัติราชการ",
//         to: "ListArea",
//         auth: [""],
//         children: [
//           {
//             title: "กำหนดพื้นที่ลงเวลา",
//             to: "CheckInArea",
//             auth: [""],
//           },
//         ],
//       },
//       {
//         title: "รอบเวลาปฏิบัติราชการ",
//         to: "RoundTimeOfWork",
//         auth: [""],
//       },
//       {
//         title: "วันหยุดประจำปี",
//         to: "Holiday",
//         auth: [""],
//       },
//       {
//         title: "ข้อมูลสะสมวันลาพักผ่อน",
//         to: "AccumulateLeave",
//         auth: [""],
//       },
//       {
//         title: "ขั้นตอนการอนุมัติ",
//         to: "Approveprocess",
//         auth: [""],
//       },
//       {
//         title: "ตำแหน่ง",
//         to: "ManagePosition",
//         auth: [""],
//       },
//       {
//         title: "จัดการโครงการ",
//         to: "ManageProject",
//         auth: [""],
//       },
//     ],
//   },
//   {
//     title: "คู่มือการใช้งานระบบ",
//     to: "Manual",
//     iconClass: "manual",
//   },
// ];

const state = {
  menus: [...menuList],
  // breadcrumbs: [...breadcrumbs],
};

export const menus = {
  namespaced: true,
  state,
};
