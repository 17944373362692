<template>
  <div id="CoreParticipator">
    <div
      class="mb-4 pb-3"
      style="border-bottom: 1px solid #ddd"
      v-for="(item, index) in parList"
      :key="index"
    >
      <div class="row ml-4">
        <div class="col-12">
          <div class="d-flex">
            <div class="form-group mb-0 d-flex align-items-center">
              <label for="firstName" class="col-form-label"
                >ชื่อ<span class=""></span>
              </label>
              <div v-if="mode !== 'view'">
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  v-model="item.firstName"
                  :class="{
                    validate: submit && !item.firstName,
                  }"
                />
                <div>
                  <span v-if="submit && !item.firstName" class="text--error">
                    <font-awesome-icon :icon="['fas', 'times-circle']" />
                    กรุณากรอกชื่อ
                  </span>
                </div>
              </div>
              <div v-else>
                {{ item.firstName }}
              </div>
            </div>
            <div class="form-group mb-0 d-flex align-items-center ml-4">
              <label for="lastName" class="col-form-label"
                >นามสกุล<span class=""></span>
              </label>
              <div class="" v-if="mode !== 'view'">
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  v-model="item.lastName"
                  :class="{
                    validate: submit && !item.lastName,
                  }"
                />
                <div>
                  <span v-if="submit && !item.lastName" class="text--error">
                    <font-awesome-icon :icon="['fas', 'times-circle']" />
                    กรุณากรอกนามสกุล
                  </span>
                </div>
              </div>
              <div v-else>
                {{ item.lastName }}
              </div>
            </div>
            <div class="ml-3" v-if="mode !== 'view'">
              <button
                v-if="parList.length > 1"
                type="button"
                class="respon-device py-1 px-4 btn-delete"
                @click="deleteRowParticipator(index)"
              >
                <font-awesome-icon icon="fa-solid fa-minus" />
                ลบ
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 font-info">
          <div
            v-for="(doc, i) in item.participatorFile"
            :key="`doc-${doc.documentTypeID}-${index}-${i}`"
            class="row d-flex align-items-center my-1"
          >
            <div class="align-items-baseline col-xl-7 col-lg-7 col-12 d-flex">
              <div class="circle-list"></div>
              <div>
                {{ doc.text || doc.documentTypeName }}
                <span class="mark-required" v-show="doc.isRequired"></span>
              </div>
            </div>
            <input
              type="file"
              :id="`docitem${index}-${i}`"
              :ref="`docitem${index}`"
              style="display: none"
              accept="application/pdf,image/*"
              @change="uploadFile($event, index, i, doc)"
            />

            <!--
                @click="chooseFiles(`docitem${index}_${i}`)"  -->
            <div class="col d-flex align-items-center">
              <button
                type="button"
                class="btn btn-light mr-3"
                v-if="mode !== 'view'"
                :id="`doc-${doc.documentTypeID}-${index}-${i}`"
                @click.prevent="$refs[`docitem${index}`][i].click()"
              >
                <font-awesome-icon icon="fa-solid fa-upload" class="pr-2" />
                อัพโหลดไฟล์
              </button>

              <div v-if="doc.file && doc.file.length > 0" class="">
                <button
                  type="button"
                  class="respon-device py-1 px-4 btn-primary mr-3"
                  @click="viewFile(doc)"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-file-lines"
                    class="pr-2"
                  />เรียกดู
                </button>
                <!-- @click="
                    () => {
                      doc.file = [];
                      $refs[`docitem${index}`][i].value = '';
                      doc.isUpdate = true;
                    }
                  " -->
                <button
                  type="button"
                  class="respon-device py-1 px-4 btn-delete"
                  @click="onDeleteClick(doc, index)"
                  v-if="mode !== 'view'"
                >
                  <font-awesome-layers class="pr-2">
                    <font-awesome-icon icon="fa-regular fa-circle" class="" />
                    <font-awesome-icon
                      icon="fa-solid fa-minus"
                      class=""
                      transform="shrink-6"
                    />
                  </font-awesome-layers>
                  ลบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="mode == 'view' && parList.length == 0">
      <div class="weight-600 font-info ml-4 my-3">ไม่มีเอกสารแนบ</div>
    </template>

    <div v-if="mode !== 'view'">
      <button
        type="button"
        class="btn btn-primary mt-2"
        @click="addRowParticipator()"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="pr-2" />
        เพิ่มข้อมูล
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "core-participator",
  props: {
    mode: {
      type: String,
      default: "add", // add, edit, view
    },
    isView: {
      type: Boolean,
      default: false,
    },
    listParticipator: {
      type: [Array, null],
      default: () => [],
    },
    listDoc: {
      type: [Array],
      default: () => [],
    },
    consentFormTypeId: {
      type: Number,
      default: 0,
    },
    personTypeID: {
      type: [Number, null],
      default: null,
    },
    consentFormID: {
      type: [String, Number, null],
      default: null,
    },
    submit: {
      type: Boolean,
      default: () => false,
    },
    refresh: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: Array,
      default: () => {
        [".pdf", ".png", ".jpg"];
      },
    },
    fileSize: {
      type: Number,
      default: () => {
        1048576; //bytes
      },
    },
    sumFileSize: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_BASE_API_DOWNLOAD,
      defaultRowParticipator: {
        firstName: null,
        lastName: null,
        personTypeID: null,
        participatorFile: [],
        // isUpdate:false,
      },
      parList: [],
      listDocLocal: [],
      refresh2: false,
      sumSize: 0,
    };
  },
  created() {
    window._coreparticipator = this;
  },
  mounted() {
    if (this.mode === "add") {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [] };
      });

      this.listDocLocal = [..._result];

      this.parList = [
        {
          ...this.defaultRowParticipator,
          participatorFile: [...this.listDocLocal],
        },
      ];
    } else if (this.mode === "edit") {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [], isUpdate: false };
      });

      this.listDocLocal = [..._result];

      if (this.listParticipator.length > 0) {
        const _p = [...val].map((m) => {
          const files = m.participatorFile.map((mm) => {
            return { ...mm, isUpdate: false };
          });

          return {
            ...m,
            participatorFile: [...files],
          };
        });
        this.parList = [..._p];
      } else {
        this.parList = [
          {
            ...this.defaultRowParticipator,
            participatorFile: [...this.listDocLocal],
          },
        ];
      }
    } else {
      if (this.listParticipator.length > 0) {
        this.parList = [...this.listParticipator];
      }
    }
  },
  methods: {
    uploadFile(e, index, indexfiles, item) {
      const fileArr = Array.from(e.target.files);

      if (this.parList[index].participatorFile[indexfiles].file.length > 0) {
        this.alertConfirm(
          "คุณต้องการแทนที่ไฟล์เดิมใช่หรือไม่ ?",
          "",
          "แทนที่",
          "ย้อนกลับ"
        ).then((res) => {
          if (res) {
            this.parList[index].participatorFile[indexfiles].file = fileArr;
            this.parList[index].participatorFile[indexfiles].isUpdate = true;
          }
        });
      } else {
        this.parList[index].participatorFile[indexfiles].file = fileArr;
        this.parList[index].participatorFile[indexfiles].isUpdate = true;
      }
    },
    async chooseFiles(ref) {
      this.$refs[`${ref}`].click();
    },
    // checkFile(i) {
    //   console.log(i);
    //   // if (this.$refs.items[i].value != null) return true;
    //   // else return false;
    //   return true;
    // },
    addRowParticipator() {
      const doc = this.listDocLocal.map((m) => {
        return {
          ...m,
          file: [],
        };
      });

      const row = {
        firstName: null,
        lastName: null,
        personTypeID: null,
        participatorFile: [...doc],
      };

      this.parList.push(row);
    },
    deleteRowParticipator(index) {
      this.parList.splice(index, 1);
    },
    viewFile(item) {
      if (this.mode === "add") {
        const fileURL = URL.createObjectURL(item.file[0]);
        window.open().location.href = fileURL;
      } else if (item.file[0].file !== null) {
        const fileURL = `${this.apiURL}${item.file[0].file}`;
        try {
          window.open(fileURL, "_blank");
        } catch (e) {}
      } else {
        this.alertFail("ไม่พบไฟล์");
      }
    },
    onDeleteClick(item, index) {
      this.alertConfirm("ยืนยันการลบเอกสาร").then((res) => {
        if (res) {
          item.file = [];
          item.isUpdate = true;
          this.$refs[`docitem${index}`][i].value = "";
        }
      });
    },
  },
  watch: {
    parList: {
      handler: function (val) {
        // console.log("listFile", val);
        const listFile = [];
        this.parList.forEach((e) => {
          e.participatorFile.forEach((file) => {
            listFile.push(file);
          });
        });
        this.sumSize = this.$utils.convert.sumFileSize(listFile);
        this.$emit("update:sumFileSize", this.sumSize);
      },
      deep: true,
    },
    refresh: {
      handler: function (val) {
        this.refresh2 = val;
      },
      deep: true,
    },
    refresh2: {
      handler: function (val, oldVal) {
        if (val) {
          this.$emit("updated", this.parList, this.invalid);

          setTimeout(() => {
            this.$emit("update:refresh", false);
          }, 100);
        }
      },
      deep: true,
    },
    listParticipator: {
      handler: function (val) {
        if (this.mode === "view") {
          if (val.length > 0) {
            this.parList = [...val];
          }
        } else if (this.mode === "edit") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [], isUpdate: false };
          });

          this.listDocLocal = [..._result];

          if (val.length > 0) {
            console.log("parrrrrrrrrrr = ", val.length);
            const _p = [...val].map((m) => {
              const files =
                m.participatorFile?.map((mm) => {
                  return { ...mm, isUpdate: false };
                }) || [];
              console.log("fileeeeeeeeeeee = ", files);
              return {
                ...m,
                participatorFile: [...files],
              };
            });
            this.parList = [..._p];
            console.log("parlistttttttttt = ", this.parList);
          } else {
            this.parList = [
              {
                ...this.defaultRowParticipator,
                participatorFile: [...this.listDocLocal],
              },
            ];
          }
        }

        // else if (this.mode !== "add" && this.listDoc.length > 0) {
        //   const _result = this.listDoc.map((m) => {
        //     return { ...m, file: [] };
        //   });

        //   this.listDocLocal = _result;
        // } else if (this.mode !== "add" && this.listDoc.length == 0) {
        // }
      },
      deep: true,
    },
    mode: {
      deep: true,
      handler: function (val) {
        if (this.mode === "add") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];

          this.parList = [
            {
              ...this.defaultRowParticipator,
              participatorFile: [...this.listDocLocal],
            },
          ];
        } else if (this.mode === "edit") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [], isUpdate: false };
          });

          this.listDocLocal = [..._result];

          if (this.listParticipator.length > 0) {
            const _p = [...this.listParticipator].map((m) => {
              const files =
                m.participatorFile?.map((mm) => {
                  return { ...mm, isUpdate: false };
                }) || [];

              return {
                ...m,
                participatorFile: [...files],
              };
            });
            this.parList = [..._p];
          } else {
            this.parList = [
              {
                ...this.defaultRowParticipator,
                participatorFile: [...this.listDocLocal],
              },
            ];
          }
        }
      },
    },
    listDoc: {
      deep: true,
      handler: function (val) {
        if (val !== null && val?.length > 0 && this.mode === "add") {
          const _result = val.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];

          this.parList = [
            {
              ...this.defaultRowParticipator,
              participatorFile: [...this.listDocLocal],
            },
          ];
        } else if (val !== null && val?.length > 0 && this.mode === "edit") {
          const _result = val.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];
        }
      },
    },
    // submit: {
    //   handler: function (val, oldVal) {
    //     this.$emit("updated", this.parList, this.invalid);
    //     // if (val) {
    //     //   this.$emit("updated", this.parList, this.invalid);
    //     // }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    invalid() {
      const result =
        this.parList.length == 0 ||
        this.parList.filter((f) => {
          return !f.firstName || !f.lastName;
        }).length > 0;
      return result;
    },
    // rowParticipator() {
    //   const doc = this.listDocLocal.map((m) => {
    //     return {
    //       ...m,
    //       file: [],
    //       // isUpdate : true
    //     };
    //   });
    //   return {
    //     firstName: null,
    //     lastName: null,
    //     personTypeID: null,
    //     participatorFile: [...doc],
    //   };
    // },
  },
};
</script>

<style></style>
