import httpClient from "../httpClient";

const END_POINT = "SystemHistory";

class SystemHistoryService {
  async getSearchSystemHistory(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchSystemHistory`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }


}

export default new SystemHistoryService();
