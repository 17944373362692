import httpClient from "../httpClient";

const END_POINT = "ManageUser";

class ManageUserService {
  async getSearchUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchUser`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getUser(userId) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetUser`,
      params: {
        UserId: userId,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postUpdateStatus(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/UpdateStatus`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response.status;
    } else {
      return response || {};
    }
  }

  async postUpdateUser(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/UpdateUser`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }

  async postCreateUser(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/CreateUser`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }

  async postDeleteUser(userId) {
    const config = {
      method: "post",
      url: `${END_POINT}/DeleteUser`,
      data: {
        userId: userId,
      },
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }

  async getCheckUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/CheckUser`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postResetPassword(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/ResetPassword`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response;
    } else {
      return response || {};
    }
  }
}

export default new ManageUserService();
