import Vue from "vue";
import VueRouter from "vue-router";
import AppLogin from "@/components/layouts/login/LoginLayout";
import PublicLayout from "@/components/layouts/public/PublicLayout";
import AdminLayout from "@/components/layouts/admin/AdminLayout";
import UserLayout from "@/components/layouts/user/UserLayout";
import OfficerLayout from "@/components/layouts/consentofficer/OfficerLayout";
import store from "@/store/index";
import { TokenService } from "@/services/storage.service";
import mainUtils from "@/utils/main-utils";

Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
};

const routes = [
  {
    path: "",
    redirect: { name: "CreateRequest" },
  },
  {
    path: "*",
    redirect: { name: "CreateRequest" },
  },
  {
    path: "/",
    component: AppLogin,
    meta: {
      login: true,
    },
    children: [
      {
        name: "LoginAdmin",
        path: "/LoginAdmin",
        component: () => import("../views/Auth/LoginAdmin.vue"),
      },
      {
        name: "LoginUser",
        path: "/LoginUser",
        component: () => import("../views/Auth/LoginUser.vue"),
      },
    ],
  },
  {
    path: "/",
    component: PublicLayout,
    meta: {
      public: true,
    },
    children: [
      {
        name: "ConfirmEmail",
        path: "/confirmemail",
        component: () => import("../views/Auth/ConfirmEmail.vue"),
      },
      {
        name: "ResetPassword",
        path: "/resetpassword",
        component: () => import("../views/Auth/ResetPassword.vue"),
      },
      {
        name: "ResendEmail",
        path: "/resendemail",
        component: () => import("../views/Auth/ResendEmail.vue"),
      },
    ],
  },
  {
    path: "/",
    component: UserLayout,
    meta: {
      public: true,
    },
    children: [
      {
        name: "ChangePassword",
        path: "/changepassword",
        component: () => import("../views/Auth/ChangePassword.vue"),
      },
      {
        name: "ForgotPassword",
        path: "/forgotpassword",
        component: () => import("../views/Auth/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/",
    component: UserLayout,
    meta: {
      public: true,
    },
    children: [
      {
        name: "User",
        path: "/UserExample",
        component: () => import("../views/Example.vue"),
      },
      {
        path: "/Manual",
        name: "Manual",
        component: () => import("../views/Manual/Manual.vue"),
      },
      {
        path: "/UserRegister",
        name: "UserRegister",
        component: () => import("../views/Auth/UserRegister.vue"),
      },
    ],
  },
  {
    path: "/",
    component: AdminLayout,
    meta: {
      requiresAuth: true,
      admin: true,
    },
    children: [
      {
        path: "Management",
        component: EmptyParentComponent,
        children: [
          {
            name: "GroupPermission",
            path: "Groups",
            component: () => import("../views/UserGroup/List.vue"),
          },
          {
            meta: {
              rootMenu: "GroupPermission",
            },
            name: "GroupFormAdd",
            path: "Groups/:mode",
            component: () => import("../views/UserGroup/Form.vue"),
          },
          {
            name: "UserManagement",
            path: "Users",
            component: () => import("../views/User/List.vue"),
          },
          {
            meta: {
              rootMenu: "UserManagement",
            },
            name: "UserFormAdd",
            path: "Users/:mode",
            component: () => import("../views/User/Form.vue"),
          },

          {
            name: "HistoryUse",
            path: "History",
            component: () => import("../views/HistoryUse/List.vue"),
          },
        ],
      },
      {
        name: "TrainingSchedule",
        path: "TrainingSchedule",
        component: () =>
          import("../views/TrainingSchedule/TrainingSchedule.vue"),
      },
      {
        path: "ServiceRequest",
        component: EmptyParentComponent,
        redirect: { name: "ServiceRequestOfficer" },
        children: [
          {
            name: "ServiceRequestOfficer",
            path: "List",
            component: () => import("../views/ServiceRequestOfficer/List.vue"),
          },
          {
            meta: {
              rootMenu: "ServiceRequestOfficer",
            },
            name: "ServiceRequestDetail",
            path: "Detail/:consentFormTypeId/:consentFormId/:status",
            component: () => import("../views/ConsentOfficer/Main.vue"),
          },
          {
            meta: {
              rootMenu: "ServiceRequestOfficer",
            },
            name: "ConsentRequestCreate",
            path: "Create",
            component: () =>
              import("../views/ServiceRequestOfficer/ConsentAdd.vue"),
          },
        ],
      },
      {
        path: "Member",
        component: EmptyParentComponent,
        children: [
          {
            name: "MemberList",
            path: "MemberList",
            component: () => import("../views/Member/List.vue"),
          },
          // {
          //   path: "Detail",
          //   name: "MemberDetail",
          //   component: () => import("../views/UserInfo/UserInfo.vue"),
          // },
        ],
      },
      {
        path: "Report",
        component: EmptyParentComponent,
        children: [
          {
            name: "DashboardReport",
            path: "Dashboard",
            component: () => import("../views/Report/Dashboard"),
          },
          {
            name: "OperationReport",
            path: "Operation",
            component: () => import("../views/Report/Operation"),
          },
        ],
      },
      {
        path: "LinkageCenter",
        redirect: { name: "LinkageCenter" },
        component: EmptyParentComponent,
        children: [
          {
            name: "LinkageCenter",
            path: "Auth",
            component: () => import("../views/LinkageCenter/Index.vue"),
          },
          {
            meta: {
              rootMenu: "LinkageCenter",
            },
            name: "LinkageVerify",
            path: "Verify",
            component: () => import("../views/LinkageCenter/Verify.vue"),
          },
          {
            meta: {
              rootMenu: "LinkageCenter",
            },
            name: "LinkageResult",
            path: "Verify/Result",
            component: () => import("../views/LinkageCenter/Result.vue"),
          },
        ],
      },
      {
        name: "OfficerManual",
        path: "OfficerManual",
        component: () => import("../views/Manual/Officer.vue"),
      },
    ],
  },
  {
    path: "/",
    component: UserLayout,
    meta: {
      requiresAuth: true,
      user: true,
    },
    children: [
      {
        path: "CreateRequest",
        redirect: { name: "CreateRequest" },
        component: EmptyParentComponent,
        children: [
          {
            name: "CreateRequest",
            path: "Menu",
            component: () => import("../views/CreateRequest/CreateRequest.vue"),
          },
          {
            name: "ConsentRequestTakePic",
            path: "ConsentRequestTakePic/:mode",
            component: () =>
              import("../views/CreateRequest/RequestTakePic/Manage.vue"),
          },
          {
            name: "ConsentPersonTemporary",
            path: "ConsentPersonTemporary/:mode",
            meta: {
              forConsentType: 3,
            },
            component: () =>
              import("../views/CreateRequest/RequestAreaPerson/Manage.vue"),
          },
          {
            name: "ConsentPersonPermanent",
            path: "ConsentPersonPermanent/:mode",
            meta: {
              forConsentType: 4,
            },
            component: () =>
              import("../views/CreateRequest/RequestAreaPerson/Manage.vue"),
          },
          {
            name: "ConsentSpecialRoom",
            path: "ConsentSpecialRoom/:mode",
            component: () =>
              import("../views/CreateRequest/RequestSpecialRoom/Manage.vue"),
          },
          {
            name: "ConsentVehicleTemporary",
            path: "ConsentVehicleTemporary/:mode",
            meta: {
              forConsentType: 5,
            },
            component: () =>
              import("../views/CreateRequest/RequestVehicle/Manage.vue"),
          },
          {
            name: "ConsentVehiclePermanent",
            path: "ConsentVehiclePermanent/:mode",
            meta: {
              forConsentType: 6,
            },
            component: () =>
              import("../views/CreateRequest/RequestVehicle/Manage.vue"),
          },

          {
            name: "ConsentRequestRenewPerson",
            path: "ConsentRequestRenewPerson/:mode",
            meta: {
              forConsentType: 7,
            },
            component: () =>
              import("../views/CreateRequest/RequestRenew/Manage.vue"),
          },

          {
            name: "ConsentRequestRenewCar",
            path: "ConsentRequestRenewCar/:mode",
            meta: {
              forConsentType: 8,
            },
            component: () =>
              import("../views/CreateRequest/RequestRenew/Manage.vue"),
          },
        ],
      },
      {
        path: "ServiceHistory",
        redirect: { name: "ServiceHistory" },
        component: EmptyParentComponent,
        children: [
          {
            path: "",
            name: "ServiceHistory",
            component: () =>
              import("../views/ServiceHistory/ServiceHistory.vue"),
          },
          {
            name: "ViewRequestTakePic",
            path: "ViewRequestTakePic",
            component: () =>
              import("../views/CreateRequest/RequestTakePic/View.vue"),
          },
          {
            name: "ViewSpecialRoom",
            path: "ViewSpecialRoom",
            component: () =>
              import("../views/CreateRequest/RequestSpecialRoom/View.vue"),
          },
          {
            name: "ViewConsentPersonTemporary",
            path: "ViewConsentPersonTemporary",
            meta: {
              forConsentType: 3,
            },
            component: () =>
              import("../views/CreateRequest/RequestAreaPerson/View.vue"),
          },
          {
            name: "ViewConsentPersonPermanent",
            path: "ViewConsentPersonPermanent",
            meta: {
              forConsentType: 4,
            },
            component: () =>
              import("../views/CreateRequest/RequestAreaPerson/View.vue"),
          },
          {
            name: "ViewConsentCarTemporary",
            path: "ViewConsentCarTemporary",
            meta: {
              forConsentType: 5,
            },
            component: () =>
              import("../views/CreateRequest/RequestVehicle/View.vue"),
          },
          {
            name: "ViewConsentCarPermanent",
            path: "ViewConsentCarPermanent",
            meta: {
              forConsentType: 6,
            },
            component: () =>
              import("../views/CreateRequest/RequestVehicle/View.vue"),
          },
          {
            name: "ViewConsentRenewPerson",
            path: "ViewConsentRenewPerson",
            meta: {
              forConsentType: 7,
            },
            component: () =>
              import("../views/CreateRequest/RequestRenew/View.vue"),
          },
          {
            name: "ViewConsentRenewCar",
            path: "ViewConsentRenewCar",
            meta: {
              forConsentType: 8,
            },
            component: () =>
              import("../views/CreateRequest/RequestRenew/View.vue"),
          },
        ],
      },
      {
        path: "/UserInfo",
        name: "UserInfo",
        component: () => import("../views/UserInfo/UserInfo.vue"),
      },
      {
        path: "/UserInfo/:editMode",
        name: "UserInfoEdit",
        component: () => import("../views/UserInfo/UserInfo.vue"),
      },
    ],
  },
  {
    path: "/",
    component: OfficerLayout,
    meta: {
      requiresAuth: true,
      admin: true,
    },
    children: [
      {
        path: "Member/Detail",
        name: "MemberDetail",
        component: () => import("../views/UserInfo/UserInfo.vue"),
      },
      {
        name: "ConsentRequestTakePicOfficer",
        path: "ConsentOfficer/Photo/:mode",
        component: () =>
          import("../views/CreateRequest/RequestTakePic/Manage.vue"),
      },
      {
        name: "ConsentSpecialRoomOfficer",
        path: "ConsentOfficer/SpecialRoom/:mode",
        component: () =>
          import("../views/CreateRequest/RequestSpecialRoom/Manage.vue"),
      },
      {
        name: "ViewSpecialRoomOfficer",
        path: "ConsentOfficer/SpecialRoom/view",
        component: () =>
          import("../views/CreateRequest/RequestSpecialRoom/View.vue"),
      },
      {
        name: "ConsentPersonTemporaryOfficer",
        path: "ConsentOfficer/PersonTemporary/:mode",
        meta: {
          forConsentType: 3,
        },
        component: () =>
          import("../views/CreateRequest/RequestAreaPerson/Manage.vue"),
      },
      {
        name: "ConsentPersonPermanentOfficer",
        path: "ConsentOfficer/PersonPermanent/:mode",
        meta: {
          forConsentType: 4,
        },
        component: () =>
          import("../views/CreateRequest/RequestAreaPerson/Manage.vue"),
      },
      {
        name: "ConsentVehicleTemporaryOfficer",
        meta: {
          forConsentType: 5,
        },
        path: "ConsentOfficer/ConsentVehicleTemporary/:mode",
        component: () =>
          import("../views/CreateRequest/RequestVehicle/Manage.vue"),
      },
      {
        name: "ConsentVehiclePermanentOfficer",
        meta: {
          forConsentType: 6,
        },
        path: "ConsentOfficer/ConsentVehiclePermanent/:mode",
        component: () =>
          import("../views/CreateRequest/RequestVehicle/Manage.vue"),
      },
      {
        name: "ConsentRequestRenewPersonOfficer",
        path: "ConsentOfficer/ConsentRequestRenewPerson/:mode",
        meta: {
          forConsentType: 7,
        },
        component: () =>
          import("../views/CreateRequest/RequestRenew/Manage.vue"),
      },
      {
        name: "ConsentRequestRenewCarOfficer",
        path: "ConsentOfficer/ConsentRequestRenewCar/:mode",
        meta: {
          forConsentType: 8,
        },
        component: () =>
          import("../views/CreateRequest/RequestRenew/Manage.vue"),
      },
    ],
  },
  {
    path: "/",
    component: PublicLayout,
    meta: {
      public: true,
      redirect: true,
    },
    children: [
      {
        path: "redirect/:mode",
        name: "RedirectPortal",
        component: () => import("../views/Redirect/Index.vue"),
      },
      // {
      //   path: "redirect/officer",
      //   name: "ReOfficer",
      //   component: () => import("../views/Redirect/Index.vue"),
      // },
    ],
  },
  {
    path: "/Example",
    name: "Example",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "example" */ "../views/Example.vue"),
  },
  {
    name: "LandingPortal",
    path: "/landing",
    component: () => import("../views/Portal/Landing.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setPageLoading", true);

  let changeNext;

  const haveToken = !!TokenService.getToken();

  const isUser = mainUtils.getIsUser() || false;

  const isOfficer = mainUtils.getIsOfficer() || false;

  const _login = to.matched.some((record) => record.meta.login);
  // const _public = to.matched.some((record) => record.meta.public);
  const _admin = to.matched.some((record) => record.meta.admin);
  const _user = to.matched.some((record) => record.meta.user);
  const _redirect = to.matched.some((record) => record.meta.redirect);

  const mode = to.params?.mode || undefined;
  const query = to.query || undefined;

  if (!haveToken && store.getters["getCitizenPortal"]) {
    window.czpSdk.closeWindow();
    return;
  }

  if (!haveToken && to.name === "CreateRequest") {
    return next(changeNext);
  }

  if ((_admin || _user) && !haveToken) {
    console.log("ROUTER: access without login");
    store.commit("setPageLoading", false);
    changeNext = { name: "LoginUser" };
  } else if (to.name === "CreateRequest" && !haveToken) {
  }
  // else if (_user && !haveToken) {
  //   console.log("ROUTER: access CreateRequest without TOKEN");
  //   changeNext = { name: "CreateRequest" };
  // }
  else if (_login && haveToken) {
    console.log("ROUTER: access login(admin/user) page with LOGIN");
    if (isUser) {
      //redirect to create requert menu
      changeNext = "CreateRequest";
    } else if (isOfficer) {
      changeNext = "ServiceRequestOfficer";
    }

    changeNext = { name: changeNext, query: { ...query } };
  }
  //  else if (_login) {
  //   console.log("ROUTER: access login(admin/user) without LOGIN");
  // }
  else if (_admin && !isOfficer) {
    console.log("ROUTER: access ADMIN without OFFICER LOGIN");
    changeNext = { name: "CreateRequest" };
  } else if (_user && isOfficer) {
    console.log("ROUTER: access USER without USER LOGIN");
    changeNext = { name: "ServiceRequestOfficer" };
  } else if (_redirect) {
    console.log("mode", mode, isOfficer, isUser);

    if (isOfficer && mode === "officer") {
      changeNext = {
        name: "ServiceRequestDetail",
        params: {
          consentFormId: query.id,
          consentFormTypeId: Number(query.t),
          status: Number(query.s),
        },
      };
    } else if (mode === "officer") {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      store.commit("account/logoutSuccess");
      store.commit("clearAllLinkage");

      //login officer
      changeNext = {
        name: "LoginAdmin",
        query: {
          redirect: true,
          ...query,
        },
      };
    } else if (isUser && mode === "personal") {
      const menu = mainUtils.getViewConsentUserByType(Number(query.t));
      changeNext = {
        name: menu,
        query: {
          id: query.id,
        },
      };
    } else if (mode === "personal") {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
      store.commit("account/logoutSuccess");
      store.commit("clearAllLinkage");

      //login user
      changeNext = {
        name: "LoginUser",
        query: {
          redirect: true,
          ...query,
        },
      };
    } else {
      return next(changeNext);
    }
  }

  return next(changeNext);
});

router.afterEach(async (to, from, next) => {
  store.commit("setPageLoading", false);
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

export default router;
