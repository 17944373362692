<template>
  <div id="OtherFile" class="mt-3">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <div class="icon-breadcrumbs"></div>
          <div class="fontsize-h5 weight-600">เอกสารแนบเพิ่มเติม (ถ้ามี)</div>
        </div>
      </div>
    </div>

    <div class="mt-3">
      <div class="font-softGray pl-4">
        กรณีมีเอกสารแนบเพิ่มเติมอัพโหลดได้ที่นี่
        โดยการลากไฟล์มาวางในกรอบด้านล่างนี้ หรือกดปุ่มอัพโหลดไฟล์เพิ่ม
      </div>
      <div class="font-softGray pl-4">
        รองรับไฟล์อัพโหลดนามสกุล .pdf, .png, .jpg และมีขนาดไฟล์ไม่เกิน 10 MB.
      </div>
      <div class="ml-4 font-info" v-if="filesUploaded2.length > 0">
        <div v-for="(item, i) in filesUploaded2" :key="i">
          <div
            v-if="!item.frontIsDelete"
            class="row d-flex align-items-center my-1"
          >
            <div class="d-flex align-items-center col-xl-7 col-lg-7 col-12">
              <div class="circle-list"></div>
              <div>
                {{ item.documentTypeName }} ({{
                  item.frontFileTemp.originalFileName
                }})
              </div>
            </div>

            <div class="col d-flex align-items-center">
              <div class="">
                <button
                  type="button"
                  class="respon-device py-1 px-4 btn-primary mr-3"
                  @click="onViewClick(item)"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-file-lines"
                    class="pr-2"
                  />เรียกดู
                </button>
                <button
                  v-if="mode !== 'view'"
                  type="button"
                  class="respon-device py-1 px-4 btn-delete"
                  @click="onDeleteFile(item)"
                >
                  <font-awesome-layers class="pr-2">
                    <font-awesome-icon icon="fa-regular fa-circle" class="" />
                    <font-awesome-icon
                      icon="fa-solid fa-minus"
                      class=""
                      transform="shrink-6"
                    />
                  </font-awesome-layers>
                  ลบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="mode === 'view' && filesUploaded2.length == 0"
        class="weight-600 ml-4 my-3 font-info"
      >
        ไม่มีเอกสารแนบ
      </div>

      <template v-if="mode !== 'view'"
        ><upload-file @change="filesOtherNew = $event" class="m-4"></upload-file
      ></template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String, // add, edit, view
      default: () => "add",
    },
    consentFormID: {
      type: [String, Number, null],
      default: null,
    },
    // submit: {
    //   type: Boolean,
    //   default: () => false,
    // },
    // invalid: {
    //   type: Boolean,
    //   default: () => false,
    // },
    filesUploaded: {
      type: Array,
      default: () => [],
    },
    resultFiles: {
      type: Array,
      default: () => [],
    },
    sumFileSize: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_BASE_API_DOWNLOAD,
      filesUploaded2: [],
      filesOtherNew: [],
      sumSize: 0,
    };
  },
  created() {
    window._otherupload = this;
  },
  mounted() {
    if (
      this.filesUploaded !== null &&
      this.filesUploaded?.length > 0 &&
      this.mode != "add"
    ) {
      this.filesUploaded2 = [...this.filesUploaded]
        .filter((f) => f.documentTypeID === 30)
        .map((m) => {
          m["frontFileTemp"] = { ...m.file[0] };
          m["frontIsDelete"] = false;
          return { ...m };
        });
    }
  },
  methods: {
    onViewClick(item) {
      // const isFile = this.$utils.convert.checkIsTypeFile(item.file[0]);

      // console.log("isFile", isFile);

      if (item.file[0].file !== null) {
        const fileURL = `${this.apiURL}${item.file[0].file}`;
        try {
          window.open(fileURL, "_blank");
        } catch (e) {}
      } else {
        this.alertFail("ไม่พบไฟล์");
      }
    },
    onDeleteFile(item) {
      this.alertConfirm("ยืนยันการลบเอกสาร").then((res) => {
        if (res) {
          item.frontIsDelete = true;
        }
      });
    },
    result() {
      let result = [];

      if (this.mode !== "add") {
        let fileOld = [];
        this.filesUploaded2.forEach(({ ...f }) => {
          const _file = f.frontIsDelete ? [] : f.file;
          const _isUpdate = f.frontIsDelete;

          delete f.frontFileTemp;
          delete f.frontIsDelete;

          fileOld.push({
            ...f,
            file: _file, //_file,
            isUpdate: _isUpdate,
          });
        });

        result.push(...fileOld);

        const fileNew = this.filesOtherNew.map((m) => {
          return {
            file: [m],
            consentFormID: this.consentFormID,
            documentTypeID: 30,
            isUpdate: true,
          };
        });

        result.push(...fileNew);
      } else {
        const fileNew = this.filesOtherNew.map((m) => {
          return {
            file: [m],
            documentTypeID: 30,
          };
        });
        result.push(...fileNew);
      }
      this.sumSize = this.$utils.convert.sumFileSize(result);
      this.$emit("update:sumFileSize", this.sumSize);
      this.$emit("update:resultFiles", result);
    },
  },
  watch: {
    mode: {
      handler: function (val) {},
      deep: true,
    },
    filesUploaded: {
      handler: function (val) {
        if (val === "add") {
        } else {
          this.filesUploaded2 = [...val]
            .filter((f) => f.documentTypeID === 30)
            .map((m) => {
              m["frontFileTemp"] = { ...m.file[0] };
              m["frontIsDelete"] = false;
              return { ...m };
            });
        }
      },
      deep: true,
    },
    filesOtherNew: {
      handler: function (val) {
        this.result();
      },
      deep: true,
    },
    filesUploaded2: {
      handler: function (val) {
        this.result();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
