<template>
  <div
    id="PublicLayout"
    class="d-flex flex-column justify-content-between px-5 py-3 PublicLayout"
  >
    <div class="logo">
      <div class="d-flex align-items-center">
        <img class="mr-3" src="@/assets/logo.png" />
        <div>
          <div class="fontsize-h4 font-navy weight-600">
            ระบบงานบริการ e-Service
          </div>
          <div>กรมท่าอากาศยาน</div>
        </div>
      </div>
    </div>

    <router-view></router-view>

    <app-footer customClass="public-footer"></app-footer>
  </div>
</template>

<script>
import AppFooter from "@/components/layouts/Footer.vue";
export default {
  name: "public-layout",
  components: {
    AppFooter,
  },
};
</script>

<style lang="scss" scoped>
.PublicLayout {
  height: 100vh;
}

.logo {
  img {
    height: 60px;
  }
}
</style>
