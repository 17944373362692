import httpClientPublic from "../httpClientPublic";
import axios from "axios";
const Linkage_Desktop_URL = "http://127.0.0.1:9001/api/";

import { StringToJson } from "@/utils/convert-utils";

class LinkageDesktopService {
  async getDesktopVersion() {
    const url = `${Linkage_Desktop_URL}desktop/getVersion`;
    return await axios
      .get(url)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        const response = err.response;
        const data = StringToJson(response.data.Message);
        return Promise.reject(data);
      });
  }

  async getFindCardReader() {
    const url = `${Linkage_Desktop_URL}IDCard/listReader`;

    return await axios
      .get(url)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        const response = err.response;
        const data = StringToJson(response.data.Message);
        return Promise.reject(data);
      });
  }

  async connectLinkage() {
    const url = `${Linkage_Desktop_URL}desktop/connect`;
    return await axios
      .get(url)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        const response = err.response;
        const data = StringToJson(response.data.Message);
        return Promise.reject(data);
      });
  }

  async connectLinkageAMI() {
    const url = `${Linkage_Desktop_URL}desktop/connectAMI`;
    return await axios
      .get(url)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        const response = err.response;
        const data = StringToJson(response.data.Message);
        return Promise.reject(data);
      });
  }

  async authOperator(nameReader) {
    const url = `${Linkage_Desktop_URL}Request/authOperator`;

    const data = {
      nameReader: nameReader,
    };

    return await axios
      .post(url, { ...data })
      .then((res) => {
        return Promise.resolve(res.data);
        //   {
        //     "status": true,
        //     "message": "Authorize Operator success.",
        //     "data": {
        //         "IsAuthenticated": true,
        //         "host": "",
        //         "xKey": "5dc826428563be164de6a147bdb59a6c",
        //         "tKey": "5dc826428563be164de6a147bdb59a6c"
        //     }
        // }
      })
      .catch((err) => {
        const response = err.response;
        const data = StringToJson(response.data.Message);
        return Promise.reject(data);
      });
  }

  async postReadCard(nameReader) {
    const url = `${Linkage_Desktop_URL}IDCard/Read`;

    const data = {
      nameReader: nameReader,
    };
    return await axios
      .post(url, { ...data })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        const response = err.response;
        const data = StringToJson(response.data.Message);
        return Promise.reject(data);
      });
  }
}

export default new LinkageDesktopService();
