import httpClient from "../httpClient";
import amphurs from "@/json/amphurs.json";
import provinces from "@/json/provinces.json";
import tambons from "@/json/tambons.json";
import zipcodes from "@/json/zipcodes.json";

const yearStart = 2022;

const END_POINT = "Filter";

class FilterService {
  async getListProvince() {
    return [...provinces].map((m) => {
      return {
        province_id: m.province_code,
        province_name: m.province_name,
      };
    });
  }

  async getListAmphor(code = null) {
    return [...amphurs]
      .filter((f) => {
        return f.province_code == code;
      })
      .map((m) => {
        return {
          province_id: m.province_code,
          amphur_id: m.amphur_code,
          amphur_name: m.amphur_name,
        };
      });
  }

  async getListTambon(code = null) {
    return [...tambons]
      .filter((f) => {
        return f.amphur_code == code;
      })
      .map((m) => {
        return {
          // province_id: m.province_code,
          amphur_id: m.amphur_code,
          tambon_id: m.tambon_code,
          tambon_code: m.tambon_code,
          tambon_name: m.tambon_name,
        };
      });
  }

  async getZipCode(tumbonCode = null) {
    return (
      [...zipcodes].filter((f) => {
        return f.tambon_code == tumbonCode;
      })[0] || { tambon_code: null, zipcode: null }
    );
  }

  async getFilterYear() {
    try {
      const date = new Date();
      let thisYear = date.getFullYear() + 1;
      let _res = [];

      for (; thisYear >= yearStart - 1; thisYear--) {
        _res.push({
          id: thisYear,
          text: thisYear + 543,
        });
      }

      return _res;
    } catch (e) {
      return [];
    }
  }

  async getFilterYearBudget() {
    try {
      const date = new Date();
      let thisYear = date.getFullYear() + 1;
      let _res = [];

      for (; thisYear >= yearStart - 1; thisYear--) {
        _res.push({
          id: thisYear,
          text: thisYear + 543,
        });
      }

      return _res;
    } catch (e) {
      return [];
    }
  }

  async getFilterAirport() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterAirport`,
      //params: data,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterSearchAirportForOfficer() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterSearchAirportForOfficer`,
      //params: data,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }
  async getFilterGroupUserByAirport(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterGroupUserByAirport`,
      params: { ...params },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterConsentFormType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterConsentFormType`,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };
    const response = await httpClient(config);
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterPrefix() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterPrefix`,
    };
    const response = await httpClient(config);
    // console.log('prefix: ', response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterDocumentType(ConsentFormTypeID, PersonTypeID = null) {
    const param = {
      ConsentFormTypeID: ConsentFormTypeID,
      PersonTypeID: PersonTypeID,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/FilterDocumentType`,
      params: param,
    };
    const response = await httpClient(config);
    // console.log('prefix: ', response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterTypePlacePhoto() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterTypePlacePhoto`,
    };
    const response = await httpClient(config);
    // console.log('prefix: ', response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterTypeOfTakePhoto() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterTypeTakePhoto`,
    };
    const response = await httpClient(config);
    // console.log('prefix: ', response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
    // return [
    //   { id: 1, text: "ภาพนิ่ง" },
    //   { id: 2, text: "ภาพเคลื่อนไหว" },
    // ];
  }

  async getFilterPersonType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterPersonType`,
    };
    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterSendType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterSendType`,
    };
    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterPaymentType() {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterPaymentType`,
    };
    const response = await httpClient(config);

    if (response && response.data) {
      // console.log("response.data",response.data)
      // let data = response.data.filter((f) => {
      //   return f.text != "E-Payment";
      // });
      // return data;
      return response.data;
    } else {
      return response || [];
    }
  }

  async getFilterPersonTypeForDoc(consentFormTypeID) {
    const config = {
      method: "get",
      url: `${END_POINT}/FilterPersonTypeForDoc`,
      params: {
        ConsentFormTypeID: consentFormTypeID,
      },
    };
    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }
}

export default new FilterService();
