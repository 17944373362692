import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
const END_POINT = "Common";

class CommonService {
  async getListStatusConsent(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetStatusConsent`,
      params: params,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getJsonProvince() {
    const config1 = {
      method: "get",
      url: `${END_POINT}/GetProvince`,
    };
    httpClientPublic(config1);

    const config2 = {
      method: "get",
      url: `${END_POINT}/GetAmphurs`,
    };
    httpClientPublic(config2);

    const config3 = {
      method: "get",
      url: `${END_POINT}/GetTambons`,
    };
    httpClientPublic(config3);

    const config4 = {
      method: "get",
      url: `${END_POINT}/GetZipcodes`,
    };
    httpClientPublic(config4);
  }
}

export default new CommonService();
