import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { faL, fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import MultiSelect from "@/components/cores/multiselect.vue";
import UploadFile from "@/components/cores/uploadFile.vue";
import CoreParticipator from "@/components/cores/participator.vue";
import CoreParticipatorSync from "@/components/cores/participatorSync.vue";
import CoreParticipatorV2 from "@/components/cores/participatorV2.vue";
import CoreParticipatorSyncV2 from "@/components/cores/participatorSyncV2.vue";

import AttachFile from "@/components/cores/attachFile.vue";
import OtherFile from "@/components/cores/otherFile.vue";

import VueDatePicker from "@/components/cores/datepicker.vue";
import Step from "@/components/cores/step.vue";
import StepNew from "@/components/cores/stepNew.vue";
import ConsentNavigation from "@/components/cores/consentNavigation.vue";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

import mainUtils from "@/utils/main-utils";
import convertUtils from "@/utils/convert-utils";
import addressUtils from "@/utils/address-utils";

import vuetify from "@/plugins/vuetify";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import VCalendar from "v-calendar";

import HighchartsVue from "highcharts-vue";

import Vuelidate from "vuelidate";

import CallDigitalSignature from "@/components/cores/callDigitalSignature.vue";
import cancelRequest from "@/components/cores/cancelRequest.vue";

library.add(fas);
library.add(far);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("multiselect", Multiselect);

Vue.use(VueSweetalert2);
Vue.component("multi-select", MultiSelect);
Vue.component("upload-file", UploadFile);
Vue.component("attach-file", AttachFile);
Vue.component("other-file", OtherFile);
Vue.component("core-participator", CoreParticipator);
Vue.component("core-participator-sync", CoreParticipatorSync);
Vue.component("core-participator-v2", CoreParticipatorV2);
Vue.component("core-participator-sync-v2", CoreParticipatorSyncV2);

Vue.component("vue-date-picker", VueDatePicker);
Vue.component("date-picker", DatePicker);

Vue.component("stepStepNew", Step);
Vue.component("step", StepNew);
Vue.component("consent-navi", ConsentNavigation);

Vue.component("call-digital-signature", CallDigitalSignature);
Vue.component("cancel-request", cancelRequest);

Vue.use(PerfectScrollbar);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(HighchartsVue);
Vue.use(Vuelidate);

Vue.prototype.$utils = {
  main: { ...mainUtils },
  convert: { ...convertUtils },
  address: { ...addressUtils },
};

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
  filters: { ...convertUtils, ...addressUtils },
  methods: {
    alertSuccess(title, text, timer = 2000) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: false,
            // confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertFail(text, timer = 2500) {
      // let _title = [];
      // if (title && title.length > 0 && typeof title === "object") {
      //   _title = title.map((m) => {
      //     return `<div class="fontsize-h3">${m}</div>`;
      //   });
      // } else if (title) {
      //   _title.push(`<div class="fontsize-h3">${title}</div>`);
      // } else {
      //   _title.push(`<div class="fontsize-h3">ทำรายการไม่สำเร็จ</div>`);
      // }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m},</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div><br>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: false,
            // confirmButtonText: "ปิด",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "error",
            timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertSuccessConfirm(title, text, timer = 2000) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            //timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertConfirm(
      title,
      text,
      confirmButtonText = "ยืนยัน",
      cancelButtonText = "ยกเลิก",
      timer = 2000
    ) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: {
              confirmButton: "btn btn-warning",
              cancelButton: "btn btn-secondary",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "question",
            reverseButtons: true,
            //timer: timer,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertLoading(title, text, timer = 3000) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">อยู่ระหว่างดำเนินการ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="d-flex justify-content-center mb-3 mt-3">
              <div class="loader"></div>
            </div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>
            `,
            showCancelButton: false,
            showConfirmButton: false,
            buttonsStyling: false,
            allowOutsideClick: false,
            // timer: timer,
            //icon: "question",
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertWithInput(title, text) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      // submit delete should be override in component
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>
            <div>
            <input id="swal-input1" class="form-control mt-3" type="text"
            placeholder="กรอก Username ของผู้มีสิทธิ์ลงลายมือชื่อ">
            <input id="swal-input2" class="form-control mt-3" type="password"
            placeholder="กรอก รหัสผ่านสำหรับลงนามอิเล็กทรอนิกส์">
            </div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ยืนยัน",
            buttonsStyling: false,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-warning",
            },
            preConfirm: () => {
              let val1 = document.getElementById("swal-input1");
              let val2 = document.getElementById("swal-input2");
              if (!val1.value) {
                val1.classList.add("validate");
              }
              if (!val2.value) {
                val2.classList.add("validate");
              }
              if (!val1.value || !val2.value) {
                this.$swal.showValidationMessage("กรุณากรอกข้อมูลให้ครบถ้วน");
              }
            },
          })
          .then(() => {
            resolve({
              userAd: document.getElementById("swal-input1").value,
              password: document.getElementById("swal-input2").value,
            });
          });
      });
    },
    alertFailConfirm(title, text, confirmButtonText = "ตกลง") {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div>${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div>${text}</div>`);
      }
      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
            
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "error",
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertForceConfirm(title, text, confirmButtonText = "ตกลง") {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "question",
          })
          .then((result) => {
            resolve(true);
          });
      });
    },
  },
});
