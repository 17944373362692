import httpClient from "../httpClient";
const END_POINT = "CallLinkage";

class CallLinkageService {
  async postReadCard() {
    const config = {
      method: "post",
      url: `${END_POINT}/Readcard`,
      // data: params,
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async postCallLinkageType1(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/CallLinkageType1`,
      data: { ...params },
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async postCallLinkageType2(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/CallLinkageType2`,
      data: { ...params },
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async postCallLinkageType3(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/CallLinkageType3`,
      data: { ...params },
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async postCallLinkageType4(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/CallLinkageType4`,
      data: { ...params },
    };

    const response = await httpClient(config);

    return response.data || {};
  }
}

export default new CallLinkageService();
