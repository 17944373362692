<template>
  <div class="UploadFile">
    <div
      class="upload-file text-center"
      @dragover.prevent
      @drop.prevent
      @drop="dragFile"
    >
      <div>
        <div class="pt-2">
          <div class="">
            <font-awesome-icon
              icon="fa-solid fa-file-arrow-up"
              class="fontsize-h1 icon-color py-2"
            />
          </div>

          <div class="">ลากไฟล์มาที่นี่</div>
        </div>
      </div>
      <div class="p-1 d-flex justify-content-center">
        <div class="line-4"></div>
        <div class="px-1 font-secondary">หรือ</div>
        <div class="line-4"></div>
      </div>

      <div class="p-2 mb-2">
        <input
          type="file"
          multiple
          @change="uploadFile"
          accept="application/pdf,image/*"
          ref="file"
          style="display: none"
        />
        <button
          type="button"
          class="btn btn-secondary"
          @click.prevent="$refs.file.click()"
        >
          อัพโหลดไฟล์
        </button>
      </div>
      <div v-if="File.length" class="p-5">
        <ul v-for="file in File" :key="file.name" class="text-certer">
          <li class="d-flex fontsize-sm">
            <div class="text-overflow">{{ splitText(file.name) }}</div>
            <div class="pr-2">
              .{{ file.name.split(".")[file.name.split(".").length - 1] }}
            </div>

            <div class="ml-auto">
              <a class="icon-delete cursor-pointer" @click="removeFile(file)"
                ><font-awesome-icon icon="fa-solid fa-trash-can"
              /></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFile",
  props: {
    // File: {
    //   type: Array,
    //   default: null,
    // },
  },
  data() {
    return {
      File: [],
    };
  },
  methods: {
    uploadFile(e) {
      if (this.File.length > 0) {
        this.alertConfirm(
          "คุณต้องการแทนที่ไฟล์เดิมใช่หรือไม่ ?",
          "",
          "แทนที่",
          "ย้อนกลับ"
        ).then((res) => {
          if (res) {
            this.File = Array.from(e.target.files);
          }
        });
      } else {
        this.File = Array.from(e.target.files);
      }
    },
    dragFile(e) {
      this.File = Array.from(e.dataTransfer.files);
    },
    removeFile(file) {
      this.alertConfirm("ยืนยันการลบเอกสาร").then((res) => {
        if (res) {
          var i = this.File.indexOf(file);
          this.File.splice(i, 1);
        }
      });
    },
    splitText(text) {
      var value = text.split(".").filter((f, i) => {
        return i != text.split(".").length - 1;
      });
      return value.join(".");
    },
  },
  watch: {
    File: {
      handler: function (val, oldVal) {
        // this.value = val
        this.$emit("change", val);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.upload-file {
  background-color: #fefefe;
  color: #363636;
  border-style: dashed;
  border-width: 2px;
  border-color: $primary;
  /* width: 500px; */
}
.icon-color {
  color: $secondary;
}
.line-4 {
  width: 4%;
  text-align: center;
  border-bottom: 1px solid $txt-secondary;
  line-height: 0.1em;
  margin: 10px 0 10px;
  color: $txt-secondary;
}
.icon-delete {
  color: $txt-danger;
  &:hover {
    color: #f78d6f;
  }
}
.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
</style>
