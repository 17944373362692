<template>
  <div id="UserLayout">
    <user-header></user-header>

    <main class="content-wrapper">
      <router-view></router-view>
    </main>

    <app-footer customClass="user-footer"></app-footer>
  </div>
</template>

<script>
import UserHeader from "./Header.vue";
import AppFooter from "@/components/layouts/Footer.vue";
export default {
  components: {
    UserHeader,
    AppFooter,
  },
};
</script>

<style></style>
