<template>
  <aside id="AdminSidebar" class="admin-sidebar">
    <header class="d-flex align-items-center px-3 m-0">
      <div class="menu-header-content">
        <img src="@/assets/logo.png" style="height: 45px; margin-right: 10px" />
        <span class="fontsize-h4">กรมท่าอากาศยาน</span>
        <div class="sidemenu-toggle-collapse" @click="sidemenuCollapse(true)">
          <font-awesome-icon
            class="m-auto"
            :icon="['fas', 'angle-double-left']"
          />
        </div>
      </div>
      <div class="menu-header-collapse" @click="sidemenuCollapse(false)">
        <font-awesome-icon class="m-auto" :icon="['fas', 'bars']" />
      </div>
    </header>
    <div class="admin-sidebar-line"></div>
    <perfect-scrollbar class="pt-3 sidebar-perfect-scrollbar">
      <nav>
        <ul>
          <template v-for="(menu, index) in menus">
            <!--  parent-level , cannot click -->
            <template v-if="menu.parentId == 0 && menu.hasSub">
              <li
                class="li-menu parent-level"
                :key="index"
                :class="{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }"
              >
                <div class="menu-name-content">
                  <font-awesome-icon
                    class="nav-icon"
                    v-if="menu.iconClass"
                    :icon="menu.iconClass"
                  />
                  <span>{{ menu.title }}</span>
                  <span class="title-collapse">{{ menu.titleCollapse }}</span>
                </div>
                <!-- <router-link class="w-100" :to="{ name: menu.to }">
                  <div class="menu-name-content">
                    <font-awesome-icon
                      class="nav-icon"
                      v-if="menu.iconClass"
                      :icon="menu.iconClass"
                    />
                    <span>{{ menu.title }}</span>
                    <span class="title-collapse">{{ menu.titleCollapse }}</span>
                  </div>
                </router-link> -->
              </li>
            </template>
            <!--  parent-level , can click -->
            <template v-else-if="menu.parentId == 0">
              <li
                class="li-menu parent-level-click"
                :key="index"
                :class="{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }"
              >
                <!-- @click="setMenuActive(menu)" -->
                <router-link
                  class="w-100"
                  :to="{ name: menu.to }"
                  :class="{ clickkkk: (menu.click = true) }"
                >
                  <div class="menu-name-content">
                    <font-awesome-icon
                      class="nav-icon"
                      v-if="menu.iconClass"
                      :icon="menu.iconClass"
                    />
                    <span class="">{{ menu.title }}</span>
                    <span class="title-collapse">{{ menu.titleCollapse }}</span>
                  </div>
                </router-link>
              </li>
            </template>
            <!-- have children -->
            <template v-else>
              <li
                class="li-menu sub-level"
                :key="index"
                :class="{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }"
              >
                <router-link class="w-100" :to="{ name: menu.to }">
                  <div class="menu-name-content">
                    <font-awesome-icon
                      class="nav-icon"
                      v-if="menu.iconClass"
                      :icon="menu.iconClass"
                    />
                    <span class="pl-4"
                      ><font-awesome-icon
                        class="mr-2"
                        icon="fa-solid fa-circle"
                        style="font-size: 8px; margin-bottom: 2px"
                      />{{ menu.title }}</span
                    >
                    <span class="title-collapse">{{ menu.titleCollapse }}</span>
                  </div>
                </router-link>
              </li>
            </template>
          </template>
          <!-- Fix menu คู่มือการใช้งานระบบ -->
          <li
            class="li-menu parent-level-click"
            :class="{
              'active-submenu': isManualActive,
              active: isManualActive,
            }"
          >
            <router-link class="w-100" :to="{ name: 'OfficerManual' }">
              <div class="menu-name-content">
                <font-awesome-icon class="nav-icon" :icon="['fas', 'file']" />
                <span class="">คู่มือการใช้งานระบบ</span>
                <span class="title-collapse">คู่มือ</span>
              </div>
            </router-link>
          </li>
        </ul>
      </nav>
      <div class="menu-controller" @click="sidemenuCollapse(false)"></div>
    </perfect-scrollbar>
    <!-- <div class="sidemenu-toggle-collapse" @click="sidemenuCollapse(true)">
      <font-awesome-icon class="m-auto" :icon="['fas', 'angle-double-left']" />
    </div> -->
  </aside>
</template>

<script>
import Header from "./Header.vue";
export default {
  components: { Header },
  name: "admin-sidebar",
  data() {
    return {
      menus: [],
    };
  },
  async created() {
    window._sidebar = this;

    let _menu = this.$utils.main.getPermissionMenu();

    this.menus = _menu
      .map((m) => {
        return {
          ...m,
          active: false,
          expand: false,
        };
      })
      .filter((f) => f.showMenu)
      .sort(function (a, b) {
        return parseFloat(a._order) - parseFloat(b._order);
      });

    this.$nextTick(() => {
      this.matchMenuSide(this.$route);
    });
  },
  methods: {
    sidemenuCollapse(value) {
      this.$emit("toggle-menu-func", value);
    },
    // findSidebar(menuId) {
    //   const permission = this.$utils.main.getPermissionMenu();
    //   const _per =
    //     permission.find((f) => {
    //       return f.menuId === menuId;
    //     }) || false;
    //   if (_per) {
    //     return _per.showMenu;
    //   }
    //   return false;
    // },
    setMenuActive(menu, isSubMenu) {
      //console.log('setMenuActive', menu, isSubMenu)
      this.menus.map((f) => {
        if (menu.menuId !== f.menuId) {
          f.active = false;
        } else {
          f.active = !f.active;
          // f.expand === null || f.expand === undefined
          //   ? !!f.expand
          //   : !f.expand
        }

        // console.log('f.expand', f.menuId, f.expand)
        return { ...f };
      });
    },
    matchMenuSide(route) {
      const _name = route.name;
      this.menus.map((f) => {
        f.active = false;
        f.expand = false;

        if (f.to === _name) {
          f.active = true;
          f.expand = true;
        } else if (f.to !== undefined && route.meta?.rootMenu === f.to) {
          f.active = true;
          f.expand = true;
        }

        // if (f.children && f.children?.length > 0) {
        //   const _sub = f.children;
        //   _sub.forEach((f2) => {
        //     if (f2.to === _name) {
        //       f.active = true;
        //       f.expand = true;
        //     }
        //   });
        // }
        return { ...f };
      });
    },
  },
  watch: {
    $route(route) {
      this.matchMenuSide(route);
    },
  },
  computed: {
    isManualActive() {
      return this.$route.name == "OfficerManual";
    },
  },
};
</script>
