<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="isManage"
    >
      <div
        v-for="(item, i) in titleManage"
        :key="i"
        class="d-flex justify-content-center align-items-center"
      >
        <!-- <div
          v-if="(item.id === 6 && !isTraining) || (item.id === 7 && !isPayment)"
        >
          {{ item.text }}
        </div> -->
        <div class="text-center">
          <font-awesome-icon
            icon="fa-regular fa-circle"
            class="font-gray fa-2x"
            v-if="item.id > currentStep"
          />
          <font-awesome-icon
            icon="fa-regular fa-circle"
            class="font-green fa-2x"
            v-else-if="item.id === currentStep"
          />
          <font-awesome-layers class="fa-2x" v-else>
            <font-awesome-icon icon="fa-solid fa-circle" class="font-green" />
            <font-awesome-icon
              icon="fa-regular fa-circle-check"
              class="font-white"
              transform="shrink-6"
            />
          </font-awesome-layers>
          <div v-if="currentStep < item.id" class="font-secondary">
            {{ item.text }}
          </div>
          <div v-else class="font-navy">
            {{ item.text }}
          </div>
        </div>

        <div
          v-if="i < titleManage.length - 1"
          :class="currentStep - 2 < i ? 'font-gray' : 'font-green'"
          class="line-step"
        ></div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center align-items-center font-stepbar"
      v-else
    >
      <perfect-scrollbar class="py-4 px-5 step-perfect-scrollbar">
        <div
          v-if="currentStep === 1 || currentStep === 2 || currentStep === 10"
          class="d-flex"
        >
          <div
            v-for="(item, i) in title"
            :key="i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="text-center"
              v-if="
                (item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && isTraining) ||
                (item.id === 8 && isPayment)
              "
            >
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-gray fa-2x"
                v-if="item.id !== 1"
              />
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-green fa-2x cursor-pointer"
                v-else-if="item.id === 1"
                @click="onClick(item)"
              />
              <font-awesome-layers
                class="fa-2x cursor-pointer"
                v-else
                @click="onClick(item)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  class="font-green"
                />
                <font-awesome-icon
                  icon="fa-regular fa-circle-check"
                  class="font-white"
                  transform="shrink-6"
                />
              </font-awesome-layers>
              <div
                v-if="item.isUsed || 1 == item.id"
                class="font-green weight-600 underline"
              >
                {{ item.text }}
              </div>
              <div v-else-if="currentStep < item.id" class="font-secondary">
                {{ item.text }}
              </div>
              <div v-else class="font-navy">
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                i < title.length - 1 &&
                ((item.id !== 6 && item.id !== 8) ||
                  (item.id === 6 && isTraining) ||
                  (item.id === 8 && isPayment))
              "
              class="font-gray line-step"
            ></div>
          </div>
        </div>

        <div
          v-else-if="currentStep === 3 || currentStep === 6"
          class="d-flex justify-content-center"
        >
          <div
            v-for="(item, i) in title"
            :key="i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="text-center"
              v-if="
                (item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && isTraining) ||
                (item.id === 8 && isPayment)
              "
            >
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-gray fa-2x"
                v-if="item.id > currentStep"
              />
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-green fa-2x cursor-pointer"
                v-else-if="item.id === currentStep"
                @click="onClick(item)"
              />
              <font-awesome-layers
                class="fa-2x cursor-pointer"
                v-else
                @click="onClick(item)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  class="font-green"
                />
                <font-awesome-icon
                  icon="fa-regular fa-circle-check"
                  class="font-white"
                  transform="shrink-6"
                />
              </font-awesome-layers>
              <div
                v-if="item.isUsed || currentSection == item.id"
                class="font-green weight-600 underline"
              >
                {{ item.text }}
              </div>
              <div v-else-if="currentStep < item.id" class="font-secondary">
                {{ item.text }}
              </div>
              <div v-else class="font-navy">
                {{ item.text }}
              </div>
            </div>

            <div
              v-if="
                i < title.length - 1 &&
                ((item.id !== 6 && item.id !== 8) ||
                  (item.id === 6 && isTraining) ||
                  (item.id === 8 && isPayment))
              "
              class="line-step"
              :class="item.id >= currentStep ? 'font-gray' : 'font-green'"
            ></div>
          </div>
        </div>

        <div
          v-else-if="currentStep === 4 || currentStep === 5"
          class="d-flex justify-content-center"
        >
          <div
            v-for="(item, i) in title"
            :key="i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="text-center"
              v-if="
                (item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && isTraining) ||
                (item.id === 8 && isPayment)
              "
            >
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-gray fa-2x"
                v-if="item.id > currentStep"
              />
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-green fa-2x cursor-pointer"
                v-else-if="item.id === currentStep"
                @click="onClick(item)"
              />
              <font-awesome-layers
                class="fa-2x cursor-pointer"
                v-else
                @click="onClick(item)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  class="font-green"
                />
                <font-awesome-icon
                  icon="fa-regular fa-circle-check"
                  class="font-white"
                  transform="shrink-6"
                />
              </font-awesome-layers>
              <div
                v-if="
                  item.isUsed ||
                  currentSection == item.id + 1 ||
                  (currentSection == item.id + 2 && currentSection != 3)
                "
                class="font-green weight-600 underline"
              >
                {{ item.text }}
              </div>
              <div v-else-if="currentStep < item.id" class="font-secondary">
                {{ item.text }}
              </div>
              <div v-else class="font-navy">
                {{ item.text }}
              </div>
            </div>

            <div
              v-if="
                i < title.length - 1 &&
                ((item.id !== 6 && item.id !== 8) ||
                  (item.id === 6 && isTraining) ||
                  (item.id === 8 && isPayment))
              "
              class="line-step"
              :class="item.id !== 1 ? 'font-gray' : 'font-green'"
            ></div>
          </div>
        </div>

        <div
          v-else-if="currentStep === 7"
          class="d-flex justify-content-center"
        >
          <div
            v-for="(item, i) in title"
            :key="i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="text-center"
              v-if="
                (item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && isTraining) ||
                (item.id === 8 && isPayment)
              "
            >
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-gray fa-2x"
                v-if="item.id > 8"
              />
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-green fa-2x cursor-pointer"
                v-else-if="item.id === 8"
                @click="onClickCurrentStep7(item)"
              />
              <font-awesome-layers
                class="fa-2x cursor-pointer"
                v-else
                @click="onClickCurrentStep7(item)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  class="font-green"
                />
                <font-awesome-icon
                  icon="fa-regular fa-circle-check"
                  class="font-white"
                  transform="shrink-6"
                />
              </font-awesome-layers>
              <div
                v-if="
                  item.isUsed ||
                  (currentSection == item.id - 1 && currentSection == 7)
                "
                class="font-green weight-600 underline"
              >
                {{ item.text }}
              </div>
              <div v-else-if="currentStep + 1 < item.id" class="font-secondary">
                {{ item.text }}
              </div>
              <div v-else class="font-navy">
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                i < title.length - 1 &&
                ((item.id !== 6 && item.id !== 8) ||
                  (item.id === 6 && isTraining) ||
                  (item.id === 8 && isPayment))
              "
              class="line-step"
              :class="item.id >= 8 ? 'font-gray' : 'font-green'"
            ></div>
          </div>
        </div>

        <div
          v-else-if="currentStep === 8"
          class="d-flex justify-content-center"
        >
          <div
            v-for="(item, i) in title"
            :key="i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="text-center"
              v-if="
                (item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && isTraining) ||
                (item.id === 8 && isPayment)
              "
            >
              <font-awesome-icon
                icon="fa-regular fa-circle"
                class="font-green fa-2x cursor-pointer"
                v-if="item.id === 9"
              />
              <font-awesome-layers
                class="fa-2x cursor-pointer"
                v-else
                @click="onClick(item)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  class="font-green"
                />
                <font-awesome-icon
                  icon="fa-regular fa-circle-check"
                  class="font-white"
                  transform="shrink-6"
                />
              </font-awesome-layers>
              <div v-if="item.isUsed" class="font-green weight-600 underline">
                {{ item.text }}
              </div>
              <!-- <div v-else-if="currentStep < item.id" class="font-secondary">
              {{ item.text }}
            </div> -->
              <div v-else class="font-navy">
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                i < title.length - 1 &&
                ((item.id !== 6 && item.id !== 8) ||
                  (item.id === 6 && isTraining) ||
                  (item.id === 8 && isPayment))
              "
              class="line-step"
              :class="item.id >= 9 ? 'font-gray' : 'font-green'"
            ></div>
          </div>
        </div>

        <div
          v-else-if="currentStep === 9"
          class="d-flex justify-content-center"
        >
          <div
            v-for="(item, i) in title"
            :key="i"
            class="d-flex justify-content-center align-items-center"
          >
            <div
              class="text-center"
              v-if="
                (item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && isTraining) ||
                (item.id === 8 && isPayment)
              "
            >
              <!-- <font-awesome-icon
              icon="fa-regular fa-circle"
              class="font-green fa-2x cursor-pointer"
              v-if="item.id === 9"
            /> -->
              <font-awesome-layers
                class="fa-2x cursor-pointer"
                @click="onClick(item)"
              >
                <font-awesome-icon
                  icon="fa-solid fa-circle"
                  class="font-green"
                />
                <font-awesome-icon
                  icon="fa-regular fa-circle-check"
                  class="font-white"
                  transform="shrink-6"
                />
              </font-awesome-layers>
              <div
                v-if="item.isUsed || currentSection == item.id"
                class="font-green weight-600 underline"
              >
                {{ item.text }}
              </div>
              <!-- <div v-else-if="currentStep < item.id" class="font-secondary">
              {{ item.text }}
            </div> -->
              <div v-else class="font-navy">
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                i < title.length - 1 &&
                ((item.id !== 6 && item.id !== 8) ||
                  (item.id === 6 && isTraining) ||
                  (item.id === 8 && isPayment))
              "
              class="font-green line-step"
            ></div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "step",
  props: {
    isManage: {
      type: Boolean,
      default: () => false,
    },
    currentSection: {
      type: Number,
      default: () => 0,
    },
    currentStep: {
      type: Number,
      default: () => 0,
    },
    isTraining: {
      type: Boolean,
      default: () => false,
    },
    isPayment: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      title: [
        { text: "ตรวจสอบเอกสาร", id: 1, isUsed: false },
        { text: "พิจารณาคำขอ", id: 3, isUsed: false },
        { text: "ทดสอบ/อบรม", id: 6, isUsed: false },
        { text: "ชำระค่าธรรมเนียม", id: 8, isUsed: false },
        { text: "ออกใบอนุญาต", id: 9, isUsed: false },
      ],
      titleManage: [
        { text: "กรอกรายละเอียดคำขอ", id: 1 },
        { text: "แนบเอกสารประกอบคำขอ", id: 2 },
      ],
    };
  },
  methods: {
    onClick(item) {
      this.title.forEach((e) => {
        e.isUsed = false;
      });
      item.isUsed = true;
      // console.log("step ", item.id);
      // console.log("currentSection ", this.currentSection);
      this.$emit("updateCurSection", item.id);
    },
    onClickCurrentStep7(item) {
      this.title.forEach((e) => {
        e.isUsed = false;
      });
      item.isUsed = true;
      let id = item.id === 8 ? 7 : item.id;
      // console.log("step ", id);
      // console.log("currentSection ", this.currentSection);
      this.$emit("updateCurSection", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.step-perfect-scrollbar {
  margin: 0;
}
.line-step {
  border-bottom: 4px solid;

  @media (max-width: 1439px) {
    width: 100px;
  }
  @media (min-width: 1440px) {
    width: 200px !important;
  }
}
</style>
