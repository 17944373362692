import httpClient from "../httpClient";

const END_POINT = "ManageMember";

class ManageMemberService {
  async getSearchMember(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/SearchMember`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }
  async postUpdateStatus(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/UpdateStatus`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.status) {
      return response.status;
    } else {
      return response || {};
    }
  }
  async getMember(id) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetMember`,
      params: {
        UserId: id,
      },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }



}

export default new ManageMemberService();
