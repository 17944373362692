var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"admin-sidebar",attrs:{"id":"AdminSidebar"}},[_c('header',{staticClass:"d-flex align-items-center px-3 m-0"},[_c('div',{staticClass:"menu-header-content"},[_c('img',{staticStyle:{"height":"45px","margin-right":"10px"},attrs:{"src":require("@/assets/logo.png")}}),_c('span',{staticClass:"fontsize-h4"},[_vm._v("กรมท่าอากาศยาน")]),_c('div',{staticClass:"sidemenu-toggle-collapse",on:{"click":function($event){return _vm.sidemenuCollapse(true)}}},[_c('font-awesome-icon',{staticClass:"m-auto",attrs:{"icon":['fas', 'angle-double-left']}})],1)]),_c('div',{staticClass:"menu-header-collapse",on:{"click":function($event){return _vm.sidemenuCollapse(false)}}},[_c('font-awesome-icon',{staticClass:"m-auto",attrs:{"icon":['fas', 'bars']}})],1)]),_c('div',{staticClass:"admin-sidebar-line"}),_c('perfect-scrollbar',{staticClass:"pt-3 sidebar-perfect-scrollbar"},[_c('nav',[_c('ul',[_vm._l((_vm.menus),function(menu,index){return [(menu.parentId == 0 && menu.hasSub)?[_c('li',{key:index,staticClass:"li-menu parent-level",class:{
                'active-submenu': menu.active || menu.expand,
                active: menu.active || menu.expand,
              }},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('font-awesome-icon',{staticClass:"nav-icon",attrs:{"icon":menu.iconClass}}):_vm._e(),_c('span',[_vm._v(_vm._s(menu.title))]),_c('span',{staticClass:"title-collapse"},[_vm._v(_vm._s(menu.titleCollapse))])],1)])]:(menu.parentId == 0)?[_c('li',{key:index,staticClass:"li-menu parent-level-click",class:{
                'active-submenu': menu.active || menu.expand,
                active: menu.active || menu.expand,
              }},[_c('router-link',{staticClass:"w-100",class:{ clickkkk: (menu.click = true) },attrs:{"to":{ name: menu.to }}},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('font-awesome-icon',{staticClass:"nav-icon",attrs:{"icon":menu.iconClass}}):_vm._e(),_c('span',{},[_vm._v(_vm._s(menu.title))]),_c('span',{staticClass:"title-collapse"},[_vm._v(_vm._s(menu.titleCollapse))])],1)])],1)]:[_c('li',{key:index,staticClass:"li-menu sub-level",class:{
                'active-submenu': menu.active || menu.expand,
                active: menu.active || menu.expand,
              }},[_c('router-link',{staticClass:"w-100",attrs:{"to":{ name: menu.to }}},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('font-awesome-icon',{staticClass:"nav-icon",attrs:{"icon":menu.iconClass}}):_vm._e(),_c('span',{staticClass:"pl-4"},[_c('font-awesome-icon',{staticClass:"mr-2",staticStyle:{"font-size":"8px","margin-bottom":"2px"},attrs:{"icon":"fa-solid fa-circle"}}),_vm._v(_vm._s(menu.title))],1),_c('span',{staticClass:"title-collapse"},[_vm._v(_vm._s(menu.titleCollapse))])],1)])],1)]]}),_c('li',{staticClass:"li-menu parent-level-click",class:{
            'active-submenu': _vm.isManualActive,
            active: _vm.isManualActive,
          }},[_c('router-link',{staticClass:"w-100",attrs:{"to":{ name: 'OfficerManual' }}},[_c('div',{staticClass:"menu-name-content"},[_c('font-awesome-icon',{staticClass:"nav-icon",attrs:{"icon":['fas', 'file']}}),_c('span',{},[_vm._v("คู่มือการใช้งานระบบ")]),_c('span',{staticClass:"title-collapse"},[_vm._v("คู่มือ")])],1)])],1)],2)]),_c('div',{staticClass:"menu-controller",on:{"click":function($event){return _vm.sidemenuCollapse(false)}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }