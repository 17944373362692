import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
import {
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
} from "@/utils/convert-utils";

const END_POINT = "HistoryConsentCustomer";

class HistoryConsentCustomerService {
  async getListHistoryConsentCustomer(data) {
    const config = {
      method: "get",
      url: `${END_POINT}/ListHistoryConsentCustomer`,
      params: data,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getHistoryConsentStatus(id) {
    const param = {
      ConsentFormID: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/HistoryConsentStatus`,
      params: param,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getHistoryConsentCustomerDetail(param) {
    const config = {
      method: "get",
      url: `${END_POINT}/HistoryConsentCustomerDetail`,
      params: param,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async postRejectConsentForm(param) {
    const data = {
      ConsentFormID: param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/RejectConsentForm`,
      params: data,
    };

    const response = await httpClient(config);

    return response;
  }

  async postUpdateHistoryConsent(param) {
    console.log("param = ", param);
    let formData = new FormData();

    const { Detail, File, Participator } = param;

    objToFormData(Detail, "Detail", formData);

    File.forEach((c, i) => {
      const { file, ...rest } = { ...c };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // Participator.forEach((c, i) => {
    //   const { file, ...rest } = { ...c };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });
    objToFormDataParticipator(formData, Participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateHistoryConsent`,
      data: formData,
    };

    // logFormData(formData);

    const response = await httpClient(config);

    return response;
  }

  async postUpdateTraining(param) {
    // const data = {
    //   ConsentFormID: param,
    // };

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateTraining`,
      data: param,
    };

    const response = await httpClient(config);

    return response;
  }
}

export default new HistoryConsentCustomerService();
