<template>
  <v-row v-if="!hasButton && type == 'date'">
    <v-col cols="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="input-group">
            <input
              type="text"
              :value="showDateTH"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :placeholder="placeholder"
              class="form-control-datepicker"
              :disabled="disabledate || disabled"
            />
            <div class="input-group-append">
              <span
                class="input-group-text"
                id="basic-addon2"
                :class="{
                  'disable-datepicker': disabledate || disabled,
                }"
              >
                <font-awesome-icon
                  :icon="['fas', 'calendar-days']"
                  class="cursor-pointer font-info"
                />
              </span>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :locale="lang"
          :max="maxDate"
          :min="minDate"
          @input="$refs.menu.save(date)"
          @change="(val) => onChange(val)"
          prev-icon="fa-chevron-left"
          next-icon="fa-chevron-right"
        >
          <!-- <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">
            OK
          </v-btn> -->
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
  <v-row v-else-if="hasButton && type == 'date'">
    <v-col cols="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="input-group">
            <input
              type="text"
              :value="showDateTH"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :placeholder="placeholder"
              class="form-control-datepicker"
              :disabled="disabledate || disabled"
            />
            <div class="input-group-append">
              <span
                class="input-group-text"
                id="basic-addon2"
                :class="{
                  'disable-datepicker': disabledate || disabled,
                }"
              >
                <font-awesome-icon
                  :icon="['fas', 'calendar-days']"
                  class="cursor-pointer font-info"
                />
              </span>
            </div>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :locale="lang"
          :max="maxDate"
          :min="minDate"
          @change="(val) => onChange(val)"
          prev-icon="fa-chevron-left"
          next-icon="fa-chevron-right"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "date-picker",
  props: {
    value: {
      type: [String, Array],
      default: null,
    },
    lang: {
      type: String,
      default: "th",
    },
    format: {
      type: String,
      default: "D MMM YY",
    },
    placeholder: {
      type: String,
      default: "กรุณาเลือกวันที่",
    },
    disabledate: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    minDate: {
      type: String,
      default: null,
    },
    maxDate: {
      type: String,
      default: null,
    },
    validate: {
      type: Boolean,
      default: () => false,
    },
    hasButton: {
      type: Boolean,
      default: () => false,
    },
    type: {
      type: String,
      default: () => "date",
    },
  },
  data() {
    return {
      date: null,
      menu: false,
      modal: false,
      menu2: false,
      time: null,
    };
  },
  mounted() {
    const app = document.createElement("div");
    app.setAttribute("data-app", true);
    document.body.append(app);
  },
  methods: {
    allowedDates(val) {
      let cond = moment();

      if (this.startDate !== null) {
        cond = moment(this.startDate) > moment(val);
      }

      if (this.endDate !== null) {
        cond = moment(val) > moment(this.endDate);
      }
      // let a = val !== null ? moment() > moment(val) : null

      return cond;
    },
    onChange(val) {
      this.$emit("change", val);
    },
  },
  computed: {
    showDateTH() {
      if (this.value !== null) {
        return moment(String(this.value))
          .add(543, "year")
          .locale("th")
          .format("D MMMM YYYY");
      } else {
        return null;
      }
    },
  },
};
</script>

<style></style>
