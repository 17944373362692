import httpClient from "../httpClient";
const END_POINT = "ConsentSendEmail";

class ConsentSendEmailService {
  async sendEmailLicense(ConsentFormID) {
    const params = { ConsentFormID: ConsentFormID };
    const config = {
      method: "get",
      url: `${END_POINT}/SendEmailLicense`,
      params: params,
    };
    const response = await httpClient(config);
    return response || {};
  }
}

export default new ConsentSendEmailService();
