var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isManage)?_c('div',{staticClass:"d-flex justify-content-center align-items-center"},_vm._l((_vm.titleManage),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"text-center"},[(item.id > _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-green fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):_c('font-awesome-layers',{staticClass:"fa-2x"},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(_vm.currentStep < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1),(i < _vm.titleManage.length - 1)?_c('div',{staticClass:"line-step",class:_vm.currentStep - 2 < i ? 'font-gray' : 'font-green'}):_vm._e()])}),0):_c('div',{staticClass:"d-flex justify-content-center align-items-center font-stepbar"},[_c('perfect-scrollbar',{staticClass:"py-4 px-5 step-perfect-scrollbar"},[(_vm.currentStep === 1 || _vm.currentStep === 2 || _vm.currentStep === 10)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
              (item.id !== 6 && item.id !== 8) ||
              (item.id === 6 && _vm.isTraining) ||
              (item.id === 8 && _vm.isPayment)
            )?_c('div',{staticClass:"text-center"},[(item.id !== 1)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === 1)?_c('font-awesome-icon',{staticClass:"font-green fa-2x cursor-pointer",attrs:{"icon":"fa-regular fa-circle"},on:{"click":function($event){return _vm.onClick(item)}}}):_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClick(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(item.isUsed || 1 == item.id)?_c('div',{staticClass:"font-green weight-600 underline"},[_vm._v(" "+_vm._s(item.text)+" ")]):(_vm.currentStep < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
              i < _vm.title.length - 1 &&
              ((item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && _vm.isTraining) ||
                (item.id === 8 && _vm.isPayment))
            )?_c('div',{staticClass:"font-gray line-step"}):_vm._e()])}),0):(_vm.currentStep === 3 || _vm.currentStep === 6)?_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
              (item.id !== 6 && item.id !== 8) ||
              (item.id === 6 && _vm.isTraining) ||
              (item.id === 8 && _vm.isPayment)
            )?_c('div',{staticClass:"text-center"},[(item.id > _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-green fa-2x cursor-pointer",attrs:{"icon":"fa-regular fa-circle"},on:{"click":function($event){return _vm.onClick(item)}}}):_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClick(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(item.isUsed || _vm.currentSection == item.id)?_c('div',{staticClass:"font-green weight-600 underline"},[_vm._v(" "+_vm._s(item.text)+" ")]):(_vm.currentStep < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
              i < _vm.title.length - 1 &&
              ((item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && _vm.isTraining) ||
                (item.id === 8 && _vm.isPayment))
            )?_c('div',{staticClass:"line-step",class:item.id >= _vm.currentStep ? 'font-gray' : 'font-green'}):_vm._e()])}),0):(_vm.currentStep === 4 || _vm.currentStep === 5)?_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
              (item.id !== 6 && item.id !== 8) ||
              (item.id === 6 && _vm.isTraining) ||
              (item.id === 8 && _vm.isPayment)
            )?_c('div',{staticClass:"text-center"},[(item.id > _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === _vm.currentStep)?_c('font-awesome-icon',{staticClass:"font-green fa-2x cursor-pointer",attrs:{"icon":"fa-regular fa-circle"},on:{"click":function($event){return _vm.onClick(item)}}}):_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClick(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(
                item.isUsed ||
                _vm.currentSection == item.id + 1 ||
                (_vm.currentSection == item.id + 2 && _vm.currentSection != 3)
              )?_c('div',{staticClass:"font-green weight-600 underline"},[_vm._v(" "+_vm._s(item.text)+" ")]):(_vm.currentStep < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
              i < _vm.title.length - 1 &&
              ((item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && _vm.isTraining) ||
                (item.id === 8 && _vm.isPayment))
            )?_c('div',{staticClass:"line-step",class:item.id !== 1 ? 'font-gray' : 'font-green'}):_vm._e()])}),0):(_vm.currentStep === 7)?_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
              (item.id !== 6 && item.id !== 8) ||
              (item.id === 6 && _vm.isTraining) ||
              (item.id === 8 && _vm.isPayment)
            )?_c('div',{staticClass:"text-center"},[(item.id > 8)?_c('font-awesome-icon',{staticClass:"font-gray fa-2x",attrs:{"icon":"fa-regular fa-circle"}}):(item.id === 8)?_c('font-awesome-icon',{staticClass:"font-green fa-2x cursor-pointer",attrs:{"icon":"fa-regular fa-circle"},on:{"click":function($event){return _vm.onClickCurrentStep7(item)}}}):_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClickCurrentStep7(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(
                item.isUsed ||
                (_vm.currentSection == item.id - 1 && _vm.currentSection == 7)
              )?_c('div',{staticClass:"font-green weight-600 underline"},[_vm._v(" "+_vm._s(item.text)+" ")]):(_vm.currentStep + 1 < item.id)?_c('div',{staticClass:"font-secondary"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
              i < _vm.title.length - 1 &&
              ((item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && _vm.isTraining) ||
                (item.id === 8 && _vm.isPayment))
            )?_c('div',{staticClass:"line-step",class:item.id >= 8 ? 'font-gray' : 'font-green'}):_vm._e()])}),0):(_vm.currentStep === 8)?_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
              (item.id !== 6 && item.id !== 8) ||
              (item.id === 6 && _vm.isTraining) ||
              (item.id === 8 && _vm.isPayment)
            )?_c('div',{staticClass:"text-center"},[(item.id === 9)?_c('font-awesome-icon',{staticClass:"font-green fa-2x cursor-pointer",attrs:{"icon":"fa-regular fa-circle"}}):_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClick(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(item.isUsed)?_c('div',{staticClass:"font-green weight-600 underline"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
              i < _vm.title.length - 1 &&
              ((item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && _vm.isTraining) ||
                (item.id === 8 && _vm.isPayment))
            )?_c('div',{staticClass:"line-step",class:item.id >= 9 ? 'font-gray' : 'font-green'}):_vm._e()])}),0):(_vm.currentStep === 9)?_c('div',{staticClass:"d-flex justify-content-center"},_vm._l((_vm.title),function(item,i){return _c('div',{key:i,staticClass:"d-flex justify-content-center align-items-center"},[(
              (item.id !== 6 && item.id !== 8) ||
              (item.id === 6 && _vm.isTraining) ||
              (item.id === 8 && _vm.isPayment)
            )?_c('div',{staticClass:"text-center"},[_c('font-awesome-layers',{staticClass:"fa-2x cursor-pointer",on:{"click":function($event){return _vm.onClick(item)}}},[_c('font-awesome-icon',{staticClass:"font-green",attrs:{"icon":"fa-solid fa-circle"}}),_c('font-awesome-icon',{staticClass:"font-white",attrs:{"icon":"fa-regular fa-circle-check","transform":"shrink-6"}})],1),(item.isUsed || _vm.currentSection == item.id)?_c('div',{staticClass:"font-green weight-600 underline"},[_vm._v(" "+_vm._s(item.text)+" ")]):_c('div',{staticClass:"font-navy"},[_vm._v(" "+_vm._s(item.text)+" ")])],1):_vm._e(),(
              i < _vm.title.length - 1 &&
              ((item.id !== 6 && item.id !== 8) ||
                (item.id === 6 && _vm.isTraining) ||
                (item.id === 8 && _vm.isPayment))
            )?_c('div',{staticClass:"font-green line-step"}):_vm._e()])}),0):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }