<template>
  <div class="cancel-request">
    <div v-if="currentStatus == 10">
      ใบคำขอนี้มีการ “<span class="weight-600">ยกเลิกคำขอ</span>” แล้ว
    </div>
    <div v-else>
      ใบคำขอนี้มีสถานะการพิจารณา “<span class="weight-600">ไม่อนุมัติ</span>”
      แล้ว
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentStatus: {
      type: Number,
      default: 5,
    },
  },
};
</script>

<style></style>
