import moment from "moment";
import axios from "axios";

const objRemoveNull = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  });

  return object;
};

// console.log("File", m.file[0] instanceof File);
// console.log("Blob", m.file[0] instanceof Blob);
// console.log("Object", m.file[0] instanceof Object);
// console.log(
//   "Array",
//   m.file[0] instanceof Array,
//   m.file instanceof Array
// );

const checkIsTypeFile = (file) => {
  return file instanceof File;
};

const checkIsTypeBlob = (file) => {
  return file instanceof Blob;
};

const checkIsTypeArray = (file) => {
  return file instanceof Array;
};

// const fnCheckDetailFileUpdate = (fileParam) => {
//   Object.keys(object).forEach((key) => {
//     if (object[key] === null) {
//       delete object[key];
//     }
//   });

//   return object;
// };

const objToFormData = (object, mainstr = null, form_data) => {
  object = objRemoveNull(object);

  for (var key in object) {
    form_data.append(`${mainstr ? mainstr + "." : ""}${key}`, object[key]);
  }

  return form_data;
};

const objToFormDataFile = (form_data, object, files = [], mainstr = null) => {
  object = objRemoveNull(object);

  for (var key in object) {
    form_data.append(`${mainstr ? mainstr + "." : ""}${key}`, object[key]);
  }

  if (files.length > 0) {
    files.forEach((file) => {
      if (checkIsTypeFile(file)) {
        form_data.append(`${mainstr ? mainstr + "." : ""}file`, file);
      } else {
        console.log("objToFormDataFile", mainstr, file);
        //1.file uploaded
        //2. no have file
        //3. array []
        //4. file is delete

        form_data.append(
          `${mainstr ? mainstr + "." : ""}file`,
          file.file || ""
        );
      }
    });
  }

  return form_data;
};

const objToFormDataParticipator = (form_data, objectList, mainstr = null) => {
  // console.log("objToFormDataParticipator", objectList);
  objectList.forEach((element, i) => {
    element = objRemoveNull(element);

    const { participatorFile, ...rest } = { ...element };
    // console.log("objToFormDataParticipator", participatorFile, rest);
    const keyP = `${mainstr}[${i}]`;

    //Participator[0]
    for (var key in rest) {
      const _f1 = `${keyP}.${key}`;
      // console.log("objToFormDataParticipator: _f1", _f1);
      form_data.append(_f1, rest[key]);
    }

    //Participator[0].participatorFile
    participatorFile.forEach((element2, i2) => {
      element2 = objRemoveNull(element2);

      const keyDoc = `file[${i2}]`;
      const { file, ...restDoc } = { ...element2 };

      for (var key2 in restDoc) {
        form_data.append(`${keyP}.${keyDoc}.${key2}`, restDoc[key2]);
      }

      //Participator[0].participatorFile.file
      if (file.length > 0) {
        file.forEach((file2) => {
          if (checkIsTypeFile(file2)) {
            form_data.append(`${keyP}.${keyDoc}.file`, file2);
          } else {
            //1.file uploaded
            //2. no have file
            //3. array []
            //4. file is delete
            form_data.append(`${keyP}.${keyDoc}.file`, file2.file);
            // form_data.append(
            //   `${mainstr ? mainstr + "." : ""}file`,
            //   file.file || ""
            // );
          }
        });
      }
      // if (file.length > 0) {
      //   file.forEach((file2) => {
      //     form_data.append(`${keyP}.${keyDoc}.file`, file2);
      //   });
      // }
    });
  });

  return form_data;
};

const logFormData = (formData) => {
  console.log("startttt");
  for (const pair of formData.entries()) {
    console.log(pair[0], ",", pair[1]);
  }
  console.log("doneeee");
};

const toFixed = (number, limit) => {
  if (number !== null && number !== "" && number !== undefined) {
    return Number(number).toFixed(limit);
  }
  return number;
};

const toCommas = (number, limit) => {
  if (
    number !== null &&
    number !== "" &&
    number !== undefined &&
    typeof number === "number"
  ) {
    // .toLocaleString()
    return Number(number)
      .toFixed(limit)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number;
  // if(number!==null && number!=='' && number!==undefined){
  //   return  Number(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  // return '0';
};

const getYearBudgetSelected = (date = null) => {
  try {
    let dateVal = date || moment();

    const todayYear = dateVal.year();
    const todayMonth = dateVal.month() + 1;

    if (todayMonth >= 10) {
      return todayYear + 1;
    }

    return todayYear;
  } catch (e) {
    return moment().year();
  }
};

const getRangeDateByYearBudget = (year = null) => {
  try {
    let yearVal = year || moment().year();

    const result = {
      start: `${yearVal - 1}-10-01`,
      end: `${yearVal}-09-30`,
    };
    return { ...result };
  } catch (e) {
    return undefined;
  }
};

const JsonToString = (json = null) => {
  try {
    if (json) {
      return JSON.stringify(json);
    } else {
      return json;
    }
  } catch (e) {
    return undefined;
  }
};

const StringToJson = (data = "") => {
  try {
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return undefined;
  }
};

const downloadFiles = (files = []) => {
  try {
    if (files.length != 0) {
      return;
    }

    const apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;
    files.forEach((c) => {
      if (c.file.length > 0) {
        axios({
          url: `${apiURL}${c.file[0].file}`,
          method: "GET",
          responseType: "blob",
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", c.file[0].originalFileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    });
  } catch (error) {
    console.log("download error: ", error);
  }
};

const sumFileSize = (listFile) => {
  let sumSize = 0;
  listFile.forEach((e) => {
    if (e.file[0] && e.file[0].size) {
      // console.log(e.file);
      sumSize = sumSize + e.file[0].size;
    }
  });
  // console.log("sumSize : ", sumSize);

  return sumSize;
};

const checkFileSize = (sizeFile, size = null) => {
  let check = null;

  if (size === null) {
    size = 30000000;
  }

  //default size => 2097152 = 2MB
  // 30000000 = 3MB

  if (sizeFile > size) {
    check = false;
  } else {
    check = true;
  }

  return check;
};

const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

const isTypeFileImage = (file = []) => {
  const _typeFile = ["image/jpeg", "image/jpg", "image/png"];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      return false;
    }
  });

  return true;
};

const isTypeFileAllowed = (file = [], typeFile = []) => {
  const _typeFile =
    typeFile !== null && typeFile.length > 0
      ? typeFile
      : [
          "application/pdf",
          "image/jpeg",
          "image/jpg",
          "image/png",
          // 'application/xls',
          // 'application/xlsx',
          // 'application/docx',
          // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      return false;
    }
  });

  return true;
};

const calAge = (date = "") => {
  try {
    if (date) {
      const result = moment().diff(date, "years", false);
      return result;
    }
    return null;
  } catch {
    return null;
  }
};

export default {
  toFixed,
  toCommas,
  getYearBudgetSelected,
  getRangeDateByYearBudget,
  JsonToString,
  StringToJson,
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
  downloadFiles,
  checkFileSize,
  sumFileSize,
  formatBytes,
  isTypeFileAllowed,
  isTypeFileImage,
  calAge,
  objRemoveNull,
  checkIsTypeFile,
};

export {
  toFixed,
  toCommas,
  getYearBudgetSelected,
  getRangeDateByYearBudget,
  JsonToString,
  StringToJson,
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
  downloadFiles,
  checkFileSize,
  sumFileSize,
  formatBytes,
  isTypeFileAllowed,
  isTypeFileImage,
  calAge,
  objRemoveNull,
};
