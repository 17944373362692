import store from "@/store/index";
import { StringToJson } from "./convert-utils";

const permission = (to, auth) => {
  //const _menu = store.state.menus.menus;

  const _per = getPermissionMenu();

  //check menu with auth
  const result =
    _per.filter((f) => {
      return f.to === to || f.auth?.indexOf(auth) > -1;
    })[0] || {};

  return {
    showMenu: result.showMenu,
    isView: result.isView,
    isModified: result.isModified,
  };
};

const findMenuId = (list, _auth) => {
  let find = [];

  list.forEach((element) => {
    if (element.auth && element.auth.includes(_auth) > 0) {
      find.push(element);
      return;
    } else if (element.children && element.children.length > 0) {
      const _menu = findMenuId(element.children, _auth);
      if (_menu) {
        find.push(_menu);
        return;
      }
    }
  });

  //console.log('find', find);

  if (find.length > 0) {
    return find[0];
  } else {
    return null;
  }
};

const getLanding = () => {
  const _menu = JSON.parse(JSON.stringify(store.state.menus.menus)) || []; // clone without reference

  let _to;

  const count = _menu.length;
  for (let i = 0; i < count; i++) {
    if (_menu[i].children && _menu[i].children.length > 0) {
      const _submenu = _menu[i].children;
      const count2 = _submenu.length;

      for (let j = 0; j < count2; j++) {
        const show = findMenuLanding(_submenu[j].menuId);
        if (show) {
          _to = _submenu[j].to;
          break;
        }
      }
    }

    if (_to) {
      break;
    }

    const per = findMenuLanding(_menu[i].menuId);

    if (per) {
      _to = _menu[i].to;
      break;
    }
  }

  return _to;
};

const findMenuLanding = (id) => {
  const permission = getPermissionMenu();
  const _per =
    permission.find((f) => {
      return f.menuId === id;
    }) || false;

  if (_per.showMenu) {
    return true;
  }
  return false;
};

const getMenuAccess = () => {
  const perm = store.state.account?.userInfo?.info?.menuAccess || "";
  return StringToJson(perm) || [];
};

const getPermissionMenu = () => {
  const _menu = store.state.menus.menus;
  const _per = getMenuAccess();

  if (getIsAdmin()) {
    return _menu.map((m) => {
      return {
        ...m,
        showMenu: true,
        isModified: true,
        isView: true,
      };
    });
  }

  //NOT ADMIN OR UserType != 4
  //compare _menu with menuAccedd for assign permission
  const menuAccess = _menu.map((m) => {
    if (m.parentId == 0) {
      const _access = _per.find((ff) => ff.id === m.menuId) || null;

      //find sub menu
      const _sub1 =
        _per.filter(
          (ff) => ff.pid === m.menuId && (ff.isView || ff.isModified)
        ) || [];
      const _subAccess = _sub1.length;

      return {
        ...m,
        showMenu: _access?.isModified || _access?.isView || _subAccess > 0,
        isModified: _access?.isModified || false,
        isView: _access?.isView || false,
      };
    } else {
      const _access = _per.find((ff) => ff.id === m.menuId) || null;
      if (_access) {
        return {
          ...m,
          showMenu: _access?.isModified || _access?.isView,
          isModified: _access?.isModified || false,
          isView: _access?.isView || false,
        };
      } else {
        return {
          ...m,
          showMenu: false,
          isModified: false,
          isView: false,
        };
      }
    }
  });

  return menuAccess;
};

const getUserInfo = () => {
  const _detail = store.state.account?.userInfo || {};
  return { ..._detail };
};

const getUserId = () => {
  return store.state.account?.userId || null;
};

const getUserName = () => {
  return store.state.account?.userInfo?.info?.userName || null;
};

const getUserType = () => {
  return store.state.account?.userInfo?.info?.usertype || null;
};

const getIsOfficer = () => {
  if (store.state.account?.userInfo?.info?.usertype) {
    return (store.state.account?.userInfo?.info?.usertype || null) != 1;
  }

  return false;
};

const getIsUser = () => {
  return (store.state.account?.userInfo?.info?.usertype || null) == 1;
};

const getIsAirportStaff = () => {
  return (store.state.account?.userInfo?.info?.usertype || null) == 2;
};

const getIsCenter = () => {
  return (store.state.account?.userInfo?.info?.usertype || null) == 3;
};

const getIsAdmin = () => {
  return (store.state.account?.userInfo?.info?.usertype || null) == 4;
};

const getAirportId = () => {
  return store.state.account?.userInfo?.info?.airportId || null;
};

const getViewConsentUserByType = (typeId) => {
  let menu = "";
  if (typeId === 1) menu = "ViewRequestTakePic";
  else if (typeId === 2) menu = "ViewSpecialRoom";
  else if (typeId === 3) menu = "ViewConsentPersonTemporary";
  else if (typeId === 4) menu = "ViewConsentPersonPermanent";
  else if (typeId === 5) menu = "ViewConsentCarTemporary";
  else if (typeId === 6) menu = "ViewConsentCarPermanent";
  else if (typeId === 7) menu = "ViewConsentRenewPerson";
  else if (typeId === 8) menu = "ViewConsentRenewCar";
  return menu;
};

const isModifiedConsentOfficer = () => {
  return permission("ServiceRequestOfficer").isModified || false;
};

const checkHaveAddressInfo = () => {
  return store.state.account?.userInfo?.haveAddress || false;
};
export default {
  permission,
  getLanding,
  getPermissionMenu,
  getUserInfo,
  getUserType,
  getIsAdmin,
  getUserId,
  getUserName,
  getIsUser,
  getAirportId,
  getIsOfficer,
  getIsAirportStaff,
  getIsCenter,
  getViewConsentUserByType,
  isModifiedConsentOfficer,
  checkHaveAddressInfo,
};
