const state = {
  authOperator: {
    // IsAuthenticated: true
    // host: ""
    // tKey: ""
    // xKey: ""
  },
  cardOwnerDetail: {},
};

const mutations = {
  setCardOwnerDetail(state, data) {
    state.cardOwnerDetail = data;
  },
  setAuthOperator(state, data) {
    state.authOperator = data;
  },
  clearCardOwnerDetail(state) {
    state.cardOwnerDetail = {};
  },
  clearAuthOperator(state) {
    state.authOperator = {};
  },
  clearAllLinkage(state) {
    state.cardOwnerDetail = {};
    state.authOperator = {};
  },
};

const getters = {
  getCardOwnerDetail: (state) => () => {
    return state.cardOwnerDetail;
  },
  getAuthOperator: (state) => () => {
    return state.authOperator;
  },
};

export default {
  state,
  mutations,
  getters,
};
