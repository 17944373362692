<template>
  <div id="UserHeader" class="app-header">
    <div class="d-flex pr-3 up-header"></div>
    <div class="align-items-center d-flex main-header px-3">
      <div
        class="header-project d-flex flex-fill align-items-center"
        :class="{ 'justify-content-around': !isRegisterPage }"
      >
        <div>
          <div class="d-flex align-items-center">
            <div>
              <img class="" src="@/assets/logo.png" />
            </div>
            <div>
              <div>
                <span class="head-project"
                  >ระบบงานบริการ
                  <span style="color: #ffd37e">e-Service</span></span
                >
              </div>
              <div class="sub-project">กรมท่าอากาศยาน</div>
            </div>
          </div>
        </div>
        <template v-if="!isRegisterPage">
          <div
            v-for="menu in menus"
            :key="`menu-${menu.name}`"
            class="menu-list d-flex align-items-center cursor-pointer"
            :class="{
              'active-menu': menu.active,
            }"
            @click="goTo(menu)"
          >
            <span
              @click="goTo(menu)"
              :class="{
                'line-active cursor-pointer': menu.active,
              }"
            ></span>
            <router-link :to="{ name: menu.name }">
              {{ menu.label }}
            </router-link>
          </div>
        </template>
      </div>
      <template v-if="!isRegisterPage">
        <div class="p-3 seperate-line" v-show="isUserLogin"></div>
        <b-navbar-nav class="nav-logout" v-show="isUserLogin">
          <b-nav-item-dropdown right class="nav-dropdown-logout">
            <template #button-content>
              <font-awesome-icon
                class="mr-3 cursor-pointer"
                :icon="['fas', 'user-group']"
              />
            </template>
            <b-dropdown-item
              href="#"
              class="font-info dropdown-item"
              @click="goTo({ name: 'UserInfo' })"
            >
              ข้อมูลผู้ใช้งาน<font-awesome-icon
                class="ml-2"
                :icon="['fas', 'user']"
              />
            </b-dropdown-item>
            <div class="dropdown-divider"></div>
            <b-dropdown-item
              href="#"
              class="font-info dropdown-item"
              @click="getLogout"
            >
              ออกจากระบบ<font-awesome-icon
                class="ml-2"
                :icon="['fas', 'arrow-right-from-bracket']"
              />
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </template>
    </div>
    <template>
      <div class="mobile-header">
        <b-navbar toggleable class="header-project w-100 mbhead">
          <b-navbar-brand href="#">
            <div class="d-flex align-items-center">
              <div>
                <img class="" src="@/assets/logo.png" />
              </div>
              <div>
                <div>
                  <span class="head-project"
                    >ระบบงานบริการ
                    <span style="color: #ffd37e">e-Service</span></span
                  >
                </div>
                <div class="sub-project">กรมท่าอากาศยาน</div>
              </div>
            </div></b-navbar-brand
          >

          <b-navbar-toggle target="navbar-toggle-collapse">
            <template #default="{ expanded }">
              <!-- <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
              <b-icon v-else icon="chevron-bar-down"></b-icon> -->
              <b-icon icon="list"></b-icon>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto" v-if="!isRegisterPage">
              <b-nav-item
                v-for="menu in menus"
                :key="`menu-${menu.name}`"
                :class="{
                  'active-menu': menu.active,
                }"
                @click="goTo(menu)"
                ><router-link :to="{ name: menu.name }">
                  {{ menu.label }}
                </router-link></b-nav-item
              >
              <b-navbar-nav class="nav-logout" v-show="isUserLogin">
                <b-nav-item-dropdown right class="nav-dropdown-logout">
                  <template #button-content>
                    <font-awesome-icon
                      class="mr-3 cursor-pointer font-white"
                      :icon="['fas', 'user-group']"
                    />
                  </template>
                  <b-dropdown-item
                    href="#"
                    class="font-info dropdown-item"
                    @click="goTo({ name: 'UserInfo' })"
                  >
                    ข้อมูลผู้ใช้งาน<font-awesome-icon
                      class="ml-2"
                      :icon="['fas', 'user']"
                    />
                  </b-dropdown-item>
                  <div class="dropdown-divider"></div>
                  <b-dropdown-item
                    href="#"
                    class="font-info dropdown-item"
                    @click="getLogout"
                  >
                    ออกจากระบบ<font-awesome-icon
                      class="ml-2"
                      :icon="['fas', 'arrow-right-from-bracket']"
                    />
                  </b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
              <!-- <b-nav-item href="#">Link 2</b-nav-item> -->
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "user-header",
  data() {
    return {
      // menus: [
      //   {
      //     index: 1,
      //     name: "CreateRequest",
      //     label: "สร้างใบคำขอใช้บริการ",
      //     active: false,
      //   },
      //   {
      //     index: 2,
      //     name: this.isUserLogin
      //       ? "ServiceHistory"
      //       : this.isOfficerLogin
      //       ? "ServiceRequestOfficer"
      //       : "LoginUser",
      //     label: "ประวัติการขอรับบริการ",
      //     active: false,
      //   },
      //   { index: 3, name: "Manual", label: "คู่มือประชาชน", active: false },
      // ],
      menu: {
        name: "CreateRequest",
        label: "สร้างใบคำขอใช้บริการ",
        active: false,
      },
    };
  },
  created() {
    window._reqheader = this;
  },
  mounted() {
    let name = this.$route.name;
    this.menus.forEach((m) => {
      if (name === m.name) {
        this.menu = m;
      }
    });
    if (name !== "UserInfo") {
      this.setMenuActive(this.menu);
    } else {
      this.setMenuActive({ name: "UserInfo" });
    }
  },
  methods: {
    goTo(menu) {
      this.$router
        .push({
          name: menu.name,
        })
        .catch(() => {});
      this.setMenuActive(menu);
    },
    setMenuActive(menu) {
      this.menus.map((f) => {
        if (menu?.name !== f.name) {
          f.active = false;
        } else {
          f.active = true;
          // this.$router.push({ name: menu.name }).catch(() => {});
        }

        return { ...f };
      });
    },
    getLogout() {
      this.alertConfirm("คุณต้องการออกจากระบบใช่หรือไม่").then((res) => {
        if (res) {
          this.$store
            .dispatch("account/logout")
            .then(
              (res) => {},
              (error) => {
                console.log("getLogout():error", error);
              }
            )
            .finally(() => {
              if (this.$store.getters["getCitizenPortal"]) {
                window.czpSdk.closeWindow();
                return;
              }

              this.$router.push({ name: "CreateRequest" });
            });
        }
      });
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.name !== "UserInfo") {
          const menu = val.path.split("/")[1] || "";
          // console.log(menu)
          this.menus.forEach((m) => {
            if (menu === m.name) {
              this.menu = m;
            }
          });
          this.setMenuActive(this.menu);
        } else {
          this.setMenuActive({ name: "UserInfo" });
        }
      },
      deep: true,
    },
  },
  computed: {
    menus() {
      const _router = this.$route;
      //const name = _router.name;

      const menu = _router.path.split("/")[1] || "";

      const list = [
        {
          index: 1,
          name: "CreateRequest",
          label: "สร้างใบคำขอใช้บริการ",
          active: menu == "CreateRequest",
        },
        {
          index: 2,
          // name: this.isUserLogin
          //   ? "ServiceHistory"
          //   : this.isOfficerLogin
          //   ? "ServiceRequestOfficer"
          //   : "LoginUser",
          // label: this.isUserLogin
          //   ? "ประวัติการขอรับบริการ"
          //   : "ข้อมูลคำขอใช้บริการ",
          name: this.isUserLogin ? "ServiceHistory" : "LoginUser",
          label: "ประวัติการขอรับบริการ",
          active: menu == "ServiceHistory",
        },
        {
          index: 3,
          name: "Manual",
          label: "คู่มือประชาชน",
          active: menu == "Manual",
        },
      ];

      return list;
    },
    isUserLogin() {
      const isUser = this.$utils.main.getIsUser() || false;
      return isUser;
    },
    // isOfficerLogin() {
    //   const isAdmin = this.$utils.main.getIsOfficer() || false;
    //   return isAdmin;
    // },
    isRegisterPage() {
      return this.$route.name === "UserRegister";
    },
  },
};
</script>

<style></style>
