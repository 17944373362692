import httpClient from "../httpClient";
const END_POINT = "ConsentAreaPermanent";
const END_POINT_OFFICER = "ConsentAreaPermanentOfficer";

import {
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
} from "@/utils/convert-utils";

class ConsentAreaPermanentService {
  async getConsentAreaPermanent(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetConsentAreaPermanent`,
      params: params,
    };
    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }
  async addConsentAreaPermanentOfficer(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT_OFFICER}/AddConsentAreaPermanent`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    logFormData(formData);

    const response = await httpClient(config);
    return response;
  }

  async addConsentAreaPermanent(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/AddConsentAreaPermanent`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    logFormData(formData);

    const response = await httpClient(config);
    return response;
  }

  async updateConsentAreaPermanent(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateConsentAreaPermanent`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    logFormData(formData);

    // return Promise.reject();
    const response = await httpClient(config);
    return response;
  }
}

export default new ConsentAreaPermanentService();
