import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({
  encodingType: "Base64",
  isCompression: false,
});

import app from "./app.module";
import { account } from "./account.module";
import { menus } from "./menus.module";
import linkage from "./linkage.module";
// import filter from "./filter.module";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: "doa-eservice",
      paths: ["account"],
      // storage: window.sessionStorage
      // storage: {
      //   getItem: key => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: key => ls.remove(key)
      // }
    }),
    createPersistedState({
      key: "linkage-desktop",
      paths: ["linkage"],
      // storage: window.sessionStorage,
      // storage: {
      //   getItem: key => ls.get(key),
      //   setItem: (key, value) => ls.set(key, value),
      //   removeItem: key => ls.remove(key)
      // }
    }),
  ],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    account,
    app,
    menus,
    linkage,
    //filter
  },
});
