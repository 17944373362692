import httpClient from "../httpClient";
const END_POINT = "ConsentRenewChangePersonal";
const END_POINT_OFFICER = "ConsentRenewChangePersonalOfficer";

import {
  objToFormData,
  objToFormDataFile,
  objToFormDataParticipator,
  logFormData,
} from "@/utils/convert-utils";

class ConsentRenewPersonService {
  async getConsentRenew(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetConsentRenewChangePersonal`,
      params: params,
    };
    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async addConsentRenew(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/AddConsentRenewChangePersonal`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    logFormData(formData);
    const response = await httpClient(config);
    return response;
  }

  async updateConsentRenew(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    // participator.forEach((element, i) => {
    //   const { file, ...rest } = { ...element };
    //   objToFormDataFile(formData, rest, file || [], `Participator[${i}]`);
    // });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateConsentRenewChangePersonal`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    logFormData(formData);

    const response = await httpClient(config);
    return response;
  }

  async addConsentRenewOfficer(param) {
    let formData = new FormData();

    const { consentDetail, consentFileUploads, participator } = param;

    objToFormData(consentDetail, "Detail", formData);

    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      objToFormDataFile(formData, rest, file || [], `File[${i}]`);
    });

    objToFormDataParticipator(formData, participator, "Participator");

    const config = {
      method: "post",
      url: `${END_POINT_OFFICER}/AddConsentRenewChangePersonal`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };

    const response = await httpClient(config);
    return response;
  }
}

export default new ConsentRenewPersonService();
