import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
import { TokenService } from "../storage.service";

const END_POINT = "Account";
const END_POINT_AUTH = "Auth";

class AccountService {
  async login(user) {
    const params = {
      userName: user.username,
      password: user.password,
    };

    const config = {
      method: "post",
      url: `${END_POINT_AUTH}/Login`,
      data: params,
      //   headers: {
      //     "content-type": "application/json",
      //   },
    };

    const response = await httpClientPublic(config);

    if (response && response?.data) {
      const { accessToken, refreshToken } = response.data;

      if (accessToken && refreshToken) {
        TokenService.saveToken(accessToken);
        TokenService.saveRefreshToken(refreshToken);
      }
    }

    return response.data || {};
  }

  async logout() {
    const params = {
      refreshToken: TokenService.getRefreshToken(),
    };

    const config = {
      method: "post",
      url: `${END_POINT_AUTH}/Logout`,
      data: params,
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await httpClient(config);

    if (response && response?.status) {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
    } else {
      TokenService.removeToken();
      TokenService.removeRefreshToken();
    }

    return response || {};
  }

  async refreshToken() {
    const config = {
      method: "post",
      url: `${END_POINT_AUTH}/RefreshToken`,
      data: {
        refreshToken: TokenService.getRefreshToken(),
      },
      headers: {
        Authorization: null,
      },
    };
    const response = await httpClientPublic(config);

    if (response && response.data) {
      const { accessToken, refreshToken } = response.data;
      if (accessToken && refreshToken) {
        TokenService.saveToken(accessToken);
        TokenService.saveRefreshToken(refreshToken);
      }
    }

    return response.data;
  }

  async getUserInfo() {
    const config = {
      method: "get",
      url: `${END_POINT_AUTH}/UserInfo`,
    };

    const response = await httpClient(config);

    if (response.status) {
      return response.data || {};
    }
    return {};
  }

  //   Account
  async postRegister(param) {
    const data = {
      ...param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/Register`,
      data: data,
    };

    const response = await httpClientPublic(config);

    console.log("res:postRegister", response);

    return response;
  }

  async postConfirmEmail(param) {
    const data = {
      ...param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/ConfirmEmail`,
      data: data,
    };

    const response = await httpClientPublic(config);
    return response;
  }

  async postResendEmail(param) {
    const data = {
      ...param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/ResendEmail`,
      data: data,
    };

    const response = await httpClientPublic(config);
    return response;
  }

  async postChangePassword(param) {
    const data = {
      ...param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/ChangePassword`,
      data: data,
    };

    const response = await httpClient(config);

    return response;
  }

  async postForgotPassword(email) {
    const data = {
      email: email,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/ForgotPassword`,
      data: data,
    };

    const response = await httpClientPublic(config);
    return response;
  }

  async postResetPassword(param) {
    const data = {
      ...param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/ResetPassword`,
      data: data,
    };

    const response = await httpClientPublic(config);

    return response;
  }

  async postUpdateUserInfo(param) {
    const data = {
      ...param,
    };

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateUserInfo`,
      data: data,
    };

    const response = await httpClient(config);

    console.log("res:UpdateUserInfo", response);

    return response;
  }
}

export default new AccountService();
