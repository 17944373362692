import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
const END_POINT = "ConsentPhoto";
const END_POINT_OFFICER = "ConsentPhotoOfficer";

import {
  objToFormData,
  objToFormDataFile,
  logFormData,
} from "@/utils/convert-utils";

class ConsentPhotoService {
  async getDetailConsentPhoto(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetDetailConsentPhoto`,
      params: params,
      headers: {
        // 'Content-Type' : 'multipart/form-data'
      },
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async addConsentPhoto(param) {
    let formData = new FormData();

    const { saveConsentPhoto, consentFileUploads } = param;

    objToFormData(saveConsentPhoto, "saveConsentPhoto", formData);
    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      console.log(file);
      objToFormDataFile(formData, rest, file || [], `consentFileUploads[${i}]`);
    });

    const config = {
      method: "post",
      url: `${END_POINT}/AddConsentPhoto`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    logFormData(formData);
    const response = await httpClient(config);
    return response;
  }

  async addConsentPhotoOfficer(param) {
    let formData = new FormData();

    const { saveConsentPhoto, consentFileUploads } = param;

    objToFormData(saveConsentPhoto, "saveConsentPhoto", formData);
    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      console.log(file);
      objToFormDataFile(formData, rest, file || [], `consentFileUploads[${i}]`);
    });

    const config = {
      method: "post",
      url: `${END_POINT_OFFICER}/AddConsentPhoto`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    logFormData(formData);
    const response = await httpClient(config);
    return response;
  }

  async updateConsentPhoto(param) {
    let formData = new FormData();

    const { saveConsentPhoto, consentFileUploads } = param;
    objToFormData(saveConsentPhoto, "saveConsentPhoto", formData);
    consentFileUploads.forEach((element, i) => {
      const { file, ...rest } = { ...element };
      console.log(file);
      objToFormDataFile(formData, rest, file || [], `consentFileUploads[${i}]`);
      // objToFormData(element, `consentFileUploads[${i}]`, formData);
      // formData.append(`consentFileUploads[${i}]`, element);
    });

    const config = {
      method: "post",
      url: `${END_POINT}/UpdateConsentPhoto`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    };
    logFormData(formData);
    // console.log("formdata : ", formData);
    const response = await httpClient(config);
    console.log("response update = ", response);
    return response;
  }
}

export default new ConsentPhotoService();
