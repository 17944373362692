<template>
  <div class="MultiSelect">
    <multiselect
      v-model="value2"
      :options="options"
      :multiple="multiple"
      :label="label"
      :track-by="trackBy"
      :selectLabel="selectLabel"
      :selectedLabel="selectedLabel"
      :deselectLabel="!allowEmpty ? '' : deselectLabel"
      :allow-empty="allowEmpty"
      :placeholder="placeholder"
      :disabled="disabled"
    >
      <span slot="noResult">ไม่มีข้อมูล</span>
      <span slot="noOptions">ไม่มีข้อมูล</span>
    </multiselect>
  </div>
</template>

<script>
export default {
  name: "MultiSelect",
  props: {
    selectedLabel: {
      type: String,
      default: "x",
    },
    selectLabel: {
      type: String,
      default: "",
    },
    deselectLabel: {
      type: String,
      default: "x",
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Object, Array, String],
      default: null,
    },
    options: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    trackBy: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "กรุณาเลือก",
    },
  },
  data() {
    return {
      value2: null,
    };
  },
  methods: {},
  mounted() {
    this.value2 = this.value;
    // this.value2 = this.value!==null?this.value:null
  },
  watch: {
    value: {
      handler: function (val, oldVal) {
        // console.log("value", val);
        this.value2 = val;
      },
      deep: true,
    },
    value2: {
      handler: function (val, oldVal) {
        // console.log("value", val);
        // this.value = val
        this.$emit("change", this.value2);
        // this.$emit("update:value", this.value2);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.multiselect__tags {
  border-color: $violet;
}
.multiselect__tag {
  background: #c4c4c44f;
  color: $black;
}
.multiselect__tag-icon:after {
  color: #ff1717;
}
.multiselect__tag-icon:hover {
  background-color: #c4c4c44f;
  color: goldenrod;
}
.multiselect__option--highlight {
  background: #1b1f3f;
  color: $white;
}
.multiselect__option--selected.multiselect__option--highlight {
  background: #f3f3f3;
  color: #35495e;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: #f3f3f3;
  color: #35495e;
}
.multiselect--disabled .multiselect__tags {
  opacity: 1;
  background-color: #ddd;
  background: #ddd;
  border: 1px solid #676091;
  overflow: hidden;
  border-radius: 6px;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ddd;
  opacity: 1;
  overflow: hidden;
  border-radius: 6px;
}
.multiselect--disabled {
  opacity: 1;
  overflow: hidden;
  border-radius: 6px;
}
.multiselect--disabled .multiselect__input,
.multiselect--disabled .multiselect__single {
  background: #ddd;
}

// .validate {
//   border: 1px solid $txt-danger;
//   opacity: 1;
//   overflow: hidden;
//   border-radius: 6px;
// }
</style>
