<template>
  <div id="CoreParticipator">
    <div
      class="mb-4 pb-3"
      style="border-bottom: 1px solid #ddd"
      v-for="(item, index) in parList"
      :key="index"
    >
      <div class="row">
        <div class="col-12 d-flex">
          <div class="underline flex-fill ml-3 weight-500 fontsize-h6">
            ข้อมูลส่วนบุคคลที่ {{ index + 1 }}
          </div>
          <div class="text-right" v-if="!isDisabledForm">
            <button
              v-if="parList.length > 1"
              type="button"
              class="respon-device py-1 px-4 btn-delete"
              @click="deleteRowParticipator(index)"
            >
              <font-awesome-icon icon="fa-solid fa-minus" />
              ลบ
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group d-flex">
            <label for="prefixName" class="col-form-label size-l"
              >คำนำหน้าชื่อ</label
            >
            <div class="w-100 d-flex align-items-center">
              <b-form-radio-group class="my-radio" v-model="item.prefixID">
                <b-form-radio
                  v-for="pref in prefixOptions"
                  :key="`prefix-${pref.id}`"
                  class="cursor-pointer"
                  :value="pref.id"
                  inline
                  :disabled="isDisabledForm"
                >
                  {{ pref.text }}
                  <div v-if="pref.id === 4" class="ml-1">
                    ระบุ
                    <input
                      v-model="item.prefixName"
                      type="text"
                      class="input-line ml-2"
                      id="prefixname"
                      :disabled="isDisabledForm"
                    />
                  </div>
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 form-responsive">
          <div class="d-flex form-group form-responsive-1">
            <label for="firstName" class="col-form-label size-l"
              >ชื่อผู้ขอรับบริการ<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="firstName"
                v-model="item.firstName"
                :class="{
                  validate: invalidInput(item.firstName),
                }"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
          <div class="d-flex form-group form-responsive-2">
            <label for="lastname" class="col-form-label"
              >นามสกุล<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="lastname"
                v-model="item.lastName"
                :class="{
                  validate: invalidInput(item.lastName),
                }"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="firstName" class="col-form-label size-l"
              >ชื่อผู้ขอรับบริการ<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="firstName"
                v-model="item.firstName"
                :class="{
                  validate: invalidInput(item.firstName),
                }"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="lastname" class="col-form-label size-l"
              >นามสกุล<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="lastname"
                v-model="item.lastName"
                :class="{
                  validate: invalidInput(item.lastName),
                }"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="positionName" class="col-form-label size-l"
              >ตำแหน่ง<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="positionName"
                v-model="item.positionName"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="department" class="col-form-label size-l"
              >แผนก/ฝ่าย<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="department"
                v-model="item.department"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="division" class="col-form-label size-l"
              >สำนัก/กอง<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="division"
                v-model="item.division"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="affiliation" class="col-form-label size-l"
              >หน่วยงานต้นสังกัด<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="affiliation"
                v-model="item.affiliation"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="idcard" class="col-form-label size-l"
              >เลขบัตรประชาชน<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="idcard"
                v-model="item.idCard"
                maxlength="13"
                placeholder="ตัวอย่าง : xxxxxxxxxxxxx ไม่ต้องใส่ (-)"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 form-responsive">
          <div class="form-group d-flex form-responsive-1">
            <label for="dob" class="col-form-label size-l"
              >วัน/เดือน/ปี เกิด<span class=""></span>
            </label>
            <div class="w-100">
              <vue-date-picker
                v-bind="$attrs"
                v-model="item.birthDate"
                :value="item.birthDate"
                :maxDate="beforeDate"
                @change="
                  (evt) => {
                    item.birthDate = evt;
                    item.age = $utils.convert.calAge(evt);
                  }
                "
                placeholder="กรุณาเลือกวัน/เดือน/ปี เกิด"
                :class="{
                  validate: invalidInput(item.birthDate),
                }"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
          <div class="form-group d-flex form-responsive-age">
            <label for="age" class="col-form-label"
              >อายุ<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="age"
                disabled
                v-model="item.age"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-responsive">
          <div class="form-group d-flex form-responsive-1">
            <label for="nationality" class="col-form-label size-l"
              >สัญชาติ<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="nationality"
                v-model="item.nationality"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
          <div class="form-group d-flex form-responsive-2">
            <label for="bloodType" class="col-form-label"
              >หมู่โลหิต<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="bloodType"
                v-model="item.bloodType"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="dob" class="col-form-label size-l"
              >วัน/เดือน/ปี เกิด<span class=""></span>
            </label>
            <div class="w-100">
              <vue-date-picker
                v-bind="$attrs"
                v-model="item.birthDate"
                :value="item.birthDate"
                :maxDate="beforeDate"
                @change="item.birthDate = $event"
                placeholder="กรุณาเลือกวัน/เดือน/ปี เกิด"
                :class="{
                  validate: !isDisabledForm && submit,
                }"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="age" class="col-form-label size-l"
              >อายุ<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="age"
                disabled
                v-model="item.age"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="nationality" class="col-form-label size-l"
              >สัญชาติ<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="nationality"
                v-model="item.nationality"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="bloodType" class="col-form-label size-l"
              >หมู่โลหิต<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="bloodType"
                v-model="item.bloodType"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 form-responsive">
          <div class="form-group d-flex form-group form-responsive-1">
            <label for="Address" class="col-form-label size-l"
              >อยู่บ้านเลขที่<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Address"
                v-model="item.address"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
          <div class="form-group d-flex form-group form-responsive-2">
            <label for="Moo" class="col-form-label">หมู่ </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Moo"
                v-model="item.moo"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="Address" class="col-form-label size-l"
              >อยู่บ้านเลขที่<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Address"
                v-model="item.address"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="Moo" class="col-form-label size-l">หมู่ </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Moo"
                v-model="item.moo"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="MooBan" class="col-form-label size-l"
              >หมู่บ้าน / อาคาร / ห้อง
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="MooBan"
                v-model="item.mooBan"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 form-responsive d-flex">
          <div class="form-group d-flex form-group form-responsive-1">
            <label for="Soi" class="col-form-label size-l"
              >ซอย<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Soi"
                v-model="item.soi"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
          <div class="form-group d-flex form-group form-responsive-2">
            <label for="Road" class="col-form-label">ถนน </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Road"
                v-model="item.road"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="Soi" class="col-form-label size-l"
              >ซอย<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Soi"
                v-model="item.soi"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-12 form-responsive-small">
          <div class="form-group d-flex">
            <label for="Road" class="col-form-label size-l">ถนน </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Road"
                v-model="item.road"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
        <template v-if="!isDisabledForm">
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="form-group d-flex">
              <label for="Province" class="col-form-label size-l"
                >จังหวัด
              </label>
              <div class="w-100">
                <multi-select
                  :options="null | getListProvince"
                  @change="($event) => changeProvince($event, item)"
                  label="province_name"
                  v-model="item.provinceValue"
                ></multi-select>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="form-group d-flex">
              <label for="Amphur" class="col-form-label size-l"
                >อำเภอ / เขต<span class=""></span>
              </label>
              <div class="w-100">
                <multi-select
                  :options="item.province | getListAmphor"
                  @change="($event) => changeAmphur($event, item)"
                  v-model="item.amphurValue"
                  label="amphur_name"
                  :disabled="item.amphurDisable"
                ></multi-select>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group d-flex">
              <label for="Tambol" class="col-form-label size-l"
                >ตำบล / แขวง
              </label>
              <div class="w-100">
                <multi-select
                  :options="item.amphur | getListTambon"
                  @change="($event) => changeTambon($event, item)"
                  v-model="item.tambolValue"
                  label="tambon_name"
                  :disabled="item.tambolDisable"
                ></multi-select>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="form-group d-flex">
              <label for="Province" class="col-form-label size-l"
                >จังหวัด
              </label>
              <div class="w-100">
                <input
                  type="text"
                  :disabled="true"
                  class="form-control"
                  :value="item.province | getProvinceName"
                />
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-12">
            <div class="form-group d-flex">
              <label for="Amphur" class="col-form-label size-l"
                >อำเภอ / เขต<span class=""></span>
              </label>
              <div class="w-100">
                <input
                  type="text"
                  :disabled="true"
                  class="form-control"
                  :value="item.amphur | getAmphurName"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="form-group d-flex">
              <label for="Tambol" class="col-form-label size-l"
                >ตำบล / แขวง
              </label>
              <div class="w-100">
                <input
                  type="text"
                  :disabled="true"
                  class="form-control"
                  :value="item.tambol | getTambonName"
                />
              </div>
            </div>
          </div>
        </template>

        <div class="col-xl-3 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="Postcode" class="col-form-label size-l"
              >รหัสไปรษณีย์
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="Postcode"
                v-model="item.postcode"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <!-- <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="email" class="col-form-label size-l"
              >อีเมล<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="email"
                v-model="item.email"
              />
            </div>
          </div>
        </div> -->
        <div class="col-xl-6 col-lg-6 col-12">
          <div class="form-group d-flex">
            <label for="phone" class="col-form-label size-l"
              >หมายเลขโทรศัพท์<span class=""></span>
            </label>
            <div class="w-100">
              <input
                type="text"
                class="form-control"
                id="phone"
                v-model="item.phoneNumber"
                :disabled="isDisabledForm"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row ml-4">
        <div class="col-12">
          <div class="d-flex align-items-center mt-4">
            <div class="icon-headline"></div>
            <div class="weight-600">
              เอกสารแนบคำขอของผู้ที่ต้องการเข้าพื้นที่
              <span class="underline">สำหรับผู้ยื่นแทน</span>
            </div>
          </div>
        </div>
        <div class="col-12 font-info">
          <div
            v-for="(doc, i) in item.participatorFile"
            :key="`doc-${doc.documentTypeID}-${index}-${i}`"
            class="row d-flex align-items-center my-1"
          >
            <div class="align-items-baseline col-xl-7 col-lg-7 col-12 d-flex">
              <div class="circle-list"></div>
              <div>
                {{ doc.text || doc.documentTypeName }}
                <span class="mark-required" v-show="doc.isRequired"></span>
              </div>
            </div>
            <input
              type="file"
              :id="`docitem${index}-${i}`"
              :ref="`docitem${index}`"
              style="display: none"
              accept="application/pdf,image/*"
              @change="uploadFile($event, index, i, doc)"
            />

            <!--
                @click="chooseFiles(`docitem${index}_${i}`)"  -->
            <div class="col d-flex align-items-center">
              <button
                type="button"
                class="btn btn-light mr-3"
                v-if="mode !== 'view'"
                :id="`doc-${doc.documentTypeID}-${index}-${i}`"
                @click.prevent="$refs[`docitem${index}`][i].click()"
              >
                <font-awesome-icon icon="fa-solid fa-upload" class="pr-2" />
                อัพโหลดไฟล์
              </button>

              <div v-if="doc.file && doc.file.length > 0" class="">
                <button
                  type="button"
                  class="respon-device py-1 px-4 btn-primary mr-3"
                  @click="viewFile(doc)"
                >
                  <font-awesome-icon
                    icon="fa-regular fa-file-lines"
                    class="pr-2"
                  />เรียกดู
                </button>
                <!-- @click="
                    () => {
                      doc.file = [];
                      $refs[`docitem${index}`][i].value = '';
                      doc.isUpdate = true;
                    }
                  " -->
                <button
                  type="button"
                  class="respon-device py-1 px-4 btn-delete"
                  @click="onDeleteClick(doc, index)"
                  v-if="!isDisabledForm"
                >
                  <font-awesome-layers class="pr-2">
                    <font-awesome-icon icon="fa-regular fa-circle" class="" />
                    <font-awesome-icon
                      icon="fa-solid fa-minus"
                      class=""
                      transform="shrink-6"
                    />
                  </font-awesome-layers>
                  ลบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="isDisabledForm && parList.length == 0">
      <div class="weight-600 font-info ml-4 my-3">ไม่มีเอกสารแนบ</div>
    </template>

    <div v-if="!isDisabledForm">
      <button
        type="button"
        class="btn btn-primary mt-2"
        @click="addRowParticipator()"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="pr-2" />
        เพิ่มข้อมูล
      </button>
    </div>
  </div>
</template>

<script>
import { FilterService } from "@/services/main.service";
import moment from "moment";

export default {
  name: "core-participator-sync-v2",
  props: {
    mode: {
      type: String,
      default: "add", // add, edit, view
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    listParticipator: {
      type: [Array, null],
      default: () => [],
    },
    listDoc: {
      type: [Array],
      default: () => [],
    },
    consentFormTypeId: {
      type: Number,
      default: 0,
    },
    personTypeID: {
      type: [Number, null],
      default: null,
    },
    consentFormID: {
      type: [String, Number, null],
      default: null,
    },
    submit: {
      type: Boolean,
      default: () => false,
    },
    fileType: {
      type: Array,
      default: () => {
        [".pdf", ".png", ".jpg"];
      },
    },
    fileSize: {
      type: Number,
      default: () => {
        1048576; //bytes
      },
    },
    sumFileSize: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      apiURL: process.env.VUE_APP_BASE_API_DOWNLOAD,
      defaultRowParticipator: {
        firstName: null,
        lastName: null,
        personTypeID: null,
        prefixID: 1,
        prefixName: null,
        idCard: null,
        birthDate: null,
        age: null,
        nationality: null,
        bloodType: null,
        phoneNumber: null,
        address: null,
        moo: null,
        mooBan: null,
        soi: null,
        road: null,
        province: null,
        amphur: null,
        tambol: null,
        postcode: null,
        positionName: null,
        department: null,
        division: null,
        affiliation: null,
        participatorFile: [],

        // provinceOptions: [],
        // amphurOptions: [],
        // tambonOptions: [],

        amphurDisable: true,
        tambolDisable: true,

        provinceValue: null,
        amphurValue: null,
        tambolValue: null,

        // isUpdate:false,
      },
      parList: [],
      listDocLocal: [],

      sumSize: 0,

      todayDate: moment().format("yyyy-MM-DD"),
      beforeDate: moment().add(-1, "days").format("yyyy-MM-DD"),
      prefixOptions: [],
    };
  },
  created() {
    window._coreparticipator = this;

    this.getPrefix();
  },
  mounted() {
    if (this.mode === "add") {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [] };
      });

      this.listDocLocal = [..._result];

      this.parList = [
        {
          ...this.defaultRowParticipator,
          participatorFile: [...this.listDocLocal],
        },
      ];
    } else if (this.mode === "edit") {
      const _result = this.listDoc.map((m) => {
        return { ...m, file: [], isUpdate: false };
      });

      this.listDocLocal = [..._result];

      if (this.listParticipator.length > 0) {
        const _p = [...val].map((m) => {
          const files = m.participatorFile.map((mm) => {
            return { ...mm, isUpdate: false };
          });

          return {
            ...m,
            participatorFile: [...files],
          };
        });
        this.parList = [..._p];
      } else {
        this.parList = [
          {
            ...this.defaultRowParticipator,
            participatorFile: [...this.listDocLocal],
          },
        ];
      }
    } else {
      if (this.listParticipator.length > 0) {
        this.parList = [...this.listParticipator];
      }
    }
  },
  methods: {
    async getPrefix() {
      await FilterService.getFilterPrefix()
        .then((res) => {
          this.prefixOptions = res;
        })
        .catch((err) => {
          this.prefixOptions = [];
        });
    },
    async changeProvince(e, item) {
      this.$nextTick(() => {
        console.log("changeProvince", e);

        item.provinceValue = e || null;
        item.province = e?.province_id || null;
        // await this.getAmphor(item.provinceValue?.province_id, item);

        item.amphurValue = null;
        item.tambolValue = null;

        item.amphurDisable = false;
        item.tambolDisable = true;

        item.amphur = null;
        item.tambol = null;
        item.postcode = null;
      });
    },
    async changeAmphur(e, item) {
      this.$nextTick(() => {
        console.log("changeAmphur", e);

        item.amphurValue = e || null;
        item.amphur = e?.amphur_id || null;
        // await this.getTambon(item.amphurValue?.amphur_id, item);
        item.tambolValue = null;
        item.tambolDisable = false;

        item.tambol = null;
        item.postcode = null;
      });
    },
    async changeTambon(e, item) {
      this.$nextTick(() => {
        console.log("changeTambon", e);

        item.tambolValue = e || null;
        item.tambol = e?.tambon_id || null;
        item.postcode = this.$utils.address.getZipCode(e?.tambon_code) || null;
      });
    },
    uploadFile(e, index, indexfiles, item) {
      const fileArr = Array.from(e.target.files);

      if (this.parList[index].participatorFile[indexfiles].file.length > 0) {
        this.alertConfirm(
          "คุณต้องการแทนที่ไฟล์เดิมใช่หรือไม่ ?",
          "",
          "แทนที่",
          "ย้อนกลับ"
        ).then((res) => {
          if (res) {
            this.parList[index].participatorFile[indexfiles].file = fileArr;
          }
        });
      } else {
        this.parList[index].participatorFile[indexfiles].file = fileArr;
      }
    },
    async chooseFiles(ref) {
      this.$refs[`${ref}`].click();
    },
    addRowParticipator() {
      const doc = this.listDocLocal.map((m) => {
        return {
          ...m,
          file: [],
        };
      });

      const row = {
        ...this.defaultRowParticipator,
        participatorFile: [...doc],
      };

      this.parList.push(row);
    },
    deleteRowParticipator(index) {
      this.parList.splice(index, 1);
    },
    viewFile(item) {
      if (this.mode === "add") {
        const fileURL = URL.createObjectURL(item.file[0]);
        window.open().location.href = fileURL;
      } else if (item.file[0].file !== null) {
        const fileURL = `${this.apiURL}${item.file[0].file}`;
        try {
          window.open(fileURL, "_blank");
        } catch (e) {}
      } else {
        this.alertFail("ไม่พบไฟล์");
      }
    },
    onDeleteClick(item, index) {
      this.alertConfirm("ยืนยันการลบเอกสาร").then((res) => {
        if (res) {
          item.file = [];
          this.$refs[`docitem${index}`][i].value = "";
        }
      });
    },
    setDetailAddress(item, { address, province, amphur, tambol, postcode }) {
      // item.province =  province;
      item.provinceValue = this.$utils.address.getProvinceObject(province);

      // item.amphur =  amphur;
      item.amphurValue = this.$utils.address.getAmphurObject(amphur);

      // item.tambol =  tambol;
      item.tambolValue = this.$utils.address.getTambonObject(tambol);

      console.log(
        "setDetailAddress",
        { address, province, amphur, tambol, postcode },
        { ...item }
      );

      return item;
    },
    invalidInput(item) {
      return !this.isDisabledForm && this.submit && !item;
    },
  },
  watch: {
    parList: {
      deep: true,
      handler: function (val) {
        const listFile = [];
        this.parList.forEach((e) => {
          e.participatorFile.forEach((file) => {
            listFile.push(file);
          });
        });
        this.sumSize = this.$utils.convert.sumFileSize(listFile);
        this.$emit("update:sumFileSize", this.sumSize);

        let invalid = false;

        this.parList.forEach((f) => {
          const _invalid =
            f.participatorFile.filter(
              (ff) => ff.isRequired && ff.file.length == 0
            ).length > 0;

          if (_invalid) {
            invalid = true;
          }
        });

        if (!invalid) {
          invalid =
            this.parList.length == 0 ||
            this.parList.filter((f) => {
              return !f.firstName || !f.lastName || !f.birthDate;
            }).length > 0;
        }

        this.$emit("update:invalid", invalid);
        this.$emit("update:listParticipator", val);
      },
    },
    listParticipator: {
      handler: function (val) {
        if (this.mode === "view") {
          if (val.length > 0) {
            this.parList = [...val];
          }
        } else if (this.mode === "edit") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [], isUpdate: false };
          });

          this.listDocLocal = [..._result];

          if (val.length > 0) {
            const _p = [...val].map((m) => {
              const files =
                m.participatorFile?.map((mm) => {
                  return { ...mm, isUpdate: false };
                }) || [];

              const { address, province, amphur, tambol, postcode } = m;

              const _mapResult = this.setDetailAddress(m, {
                address,
                province,
                amphur,
                tambol,
                postcode,
              });
              return {
                ...m,
                ..._mapResult,
                participatorFile: [...files],
              };
            });
            this.parList = [..._p];
          } else {
            this.parList = [
              {
                ...this.defaultRowParticipator,
                participatorFile: [...this.listDocLocal],
              },
            ];
          }
        }
      },
      deep: true,
    },
    mode: {
      deep: true,
      handler: function (val) {
        if (this.mode === "add") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];

          this.parList = [
            {
              ...this.defaultRowParticipator,
              participatorFile: [...this.listDocLocal],
            },
          ];
        } else if (this.mode === "edit") {
          const _result = this.listDoc.map((m) => {
            return { ...m, file: [], isUpdate: false };
          });

          this.listDocLocal = [..._result];

          if (this.listParticipator.length > 0) {
            const _p = [...this.listParticipator].map((m) => {
              const files =
                m.participatorFile?.map((mm) => {
                  return { ...mm, isUpdate: false };
                }) || [];

              const { address, province, amphur, tambol, postcode } = m;

              const _mapResult = this.setDetailAddress(m, {
                address,
                province,
                amphur,
                tambol,
                postcode,
              });

              return {
                ...m,
                ..._mapResult,
                participatorFile: [...files],
              };
            });

            this.parList = [..._p];
          } else {
            this.parList = [
              {
                ...this.defaultRowParticipator,
                participatorFile: [...this.listDocLocal],
              },
            ];
          }
        }
      },
    },
    listDoc: {
      deep: true,
      handler: function (val) {
        if (val !== null && val?.length > 0 && this.mode === "add") {
          const _result = val.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];

          this.parList = [
            {
              ...this.defaultRowParticipator,
              participatorFile: [...this.listDocLocal],
            },
          ];
        } else if (val !== null && val?.length > 0 && this.mode === "edit") {
          const _result = val.map((m) => {
            return { ...m, file: [] };
          });

          this.listDocLocal = [..._result];
        }
      },
    },
  },
  computed: {
    // isInvalid() {
    //   const result =
    //     this.parList.length == 0 ||
    //     this.parList.filter((f) => {
    //       return !f.firstName || !f.lastName;
    //     }).length > 0;

    //   this.$emit("update:invalid", result);

    //   return result;
    // },
    isDisabledForm() {
      return this.isview || this.mode == "view";
    },
  },
};
</script>

<style></style>
