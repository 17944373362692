import httpClient from "../httpClient";
// import httpClientPublic from "../httpClientPublic";
const END_POINT = "Consent";

import { objToFormData, objToFormDataFile } from "@/utils/convert-utils";
class ConsentService {
  async getListConsentOfficer(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetListConsentOfficer`,
      params: params,
    };
    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return response || {};
    }
  }

  async getDetailConsentOfficer(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetDetailConsentOfficer`,
      params: params,
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getDetailConsentForOfficer(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetDetailConsentForOfficer`,
      params: params,
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postSetStatusConsent(param) {
    let formData = new FormData();

    const { carCritetia, participator, ...rest } = param;

    objToFormData(rest, "", formData);

    if (carCritetia) {
      carCritetia.forEach((element, i) => {
        const { file, ...restCar } = { ...element };
        objToFormDataFile(formData, restCar, file || [], `carCritetia[${i}]`);
      });
    }

    if (participator) {
      participator.forEach((element, i) => {
        const { file, ...restP } = { ...element };
        objToFormDataFile(formData, restP, file || [], `participator[${i}]`);
      });
    }

    const config = {
      method: "post",
      url: `${END_POINT}/SetStatusConsent`,
      data: formData,
    };

    const response = await httpClient(config);

    return response;
  }

  async postDeleteConsent(params) {
    const config = {
      method: "put",
      url: `${END_POINT}/DeleteConsent`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getConsentLicense(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetConsentLicense`,
      params: params,
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
    // return {
    //   status: true,
    // };
  }

  async getLicense(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/GetLicense`,
      params: params,
    };
    const response = await httpClient(config);
    // console.log("response ",response)
    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
    // return { status: true, file: null };
  }

  async SaveConsent(param) {
    let formData = new FormData();

    const {
      consentFileUploads,
      training,
      approval,
      fee,
      consentDetail,
      vehicle,
      ...rest
    } = param;

    objToFormData(consentDetail, "ConsentDetail", formData);
    objToFormData(approval, "ApprovalDetail", formData);
    if (fee) {
      objToFormData(fee, "FeeDeatil", formData);
    }
    objToFormData(rest, "", formData);

    if (consentFileUploads) {
      consentFileUploads.forEach((element, i) => {
        const { fileDetails, ...rest } = { ...element };
        objToFormDataFile(formData, rest, [], `ConsentFiles[${i}]`);
        fileDetails.forEach((e, j) => {
          const { file, ...restFile } = { ...e };
          objToFormDataFile(
            formData,
            restFile,
            file || [],
            `ConsentFiles[${i}].FileDetails[${j}]`
          );
        });
      });
    }

    if (training) {
      training.forEach((element, i) => {
        const { fileTraining, ...restTrain } = { ...element };
        objToFormDataFile(formData, restTrain, [], `TrainingDetails[${i}]`);
        fileTraining.forEach((e, j) => {
          const { file, ...restFile } = { ...e };
          objToFormDataFile(
            formData,
            restFile,
            file || [],
            `TrainingDetails[${i}].FileTrainings[${j}]`
          );
        });
      });
    }

    if (vehicle) {
      vehicle.forEach((element, i) => {
        const { fileVehicle, ...restVehicle } = { ...element };
        objToFormDataFile(formData, restVehicle, [], `VehicleDetails[${i}]`);
        // fileVehicle.forEach((e, j) => {
        //   objToFormDataFile(
        //     formData,
        //     {},
        //     e || [],
        //     `VehicleDetails[${i}].file[${j}]`
        //   );
        // });
      });
    }

    const config = {
      method: "post",
      url: `${END_POINT}/SaveConsent`,
      data: formData,
    };

    const response = await httpClient(config);

    return response;
  }
}

export default new ConsentService();
